import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_CONSUMABLES_DATA,
	CLEAR_ALL_CONSUMABLES_ERRORS,
	CLEAR_CONSUMABLES_SINGLE_ERROR,
	CONSUMABLE_CREATE_LOADING,
	SET_CONSUMABLE_ID,
	CONSUMABLE_CREATE_ERROR,
	ALL_CONSUMABLES_LOADING,
	ALL_CONSUMABLES_GET,
	ALL_CONSUMABLES_ERROR,
	CONSUMABLE_INFO_LOADING,
	CONSUMABLE_INFO_GET,
	CONSUMABLE_INFO_ERROR,
	CONSUMABLE_UPDATE_LOADING,
	CONSUMABLE_UPDATE_ERROR,
	CONSUMABLE_DELETE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_all_consumables_data = () => dispatch => {
	dispatch({
		type: CLEAR_ALL_CONSUMABLES_DATA
	});
}

export const clear_consumables_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_CONSUMABLES_SINGLE_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_CONSUMABLES_ERRORS
		});
	}
}

export const set_consumable_create_id = (consumableId) => dispatch => {
	dispatch({
		type: SET_CONSUMABLE_ID,
		payload: consumableId
	});
}

export const consumable_create = (consumableBody) => dispatch => {
	dispatch({
		type: CONSUMABLE_CREATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/consumables/create';

	Axios.post(url, consumableBody)
	.then((res) => {
		// console.log(res.data);
		dispatch(set_consumable_create_id(res.data.consumable));

		dispatch({
			type: CONSUMABLE_CREATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CONSUMABLE_CREATE_ERROR,
			payload: { type: 'consumable_create', msg: err.message }
		});

		dispatch({
			type: CONSUMABLE_CREATE_LOADING,
			payload: false
		});
	})
}

export const get_all_consumables = (filters) => dispatch => {
	dispatch({
		type: ALL_CONSUMABLES_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/game/consumables?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newConsumablesArray = add_key_prop(res.data.consumables);
		res.data.consumables = newConsumablesArray;

		dispatch({
			type: ALL_CONSUMABLES_GET,
			payload: res.data
		});

		dispatch({
			type: ALL_CONSUMABLES_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ALL_CONSUMABLES_ERROR,
			payload: { type: 'get_all_consumables', msg: err.message }
		});

		dispatch({
			type: ALL_CONSUMABLES_LOADING,
			payload: false
		});
	})
}

export const get_consumable_info = (consumableId) => dispatch => {
	dispatch({
		type: CONSUMABLE_INFO_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/consumables/${consumableId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: CONSUMABLE_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: CONSUMABLE_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: CONSUMABLE_INFO_ERROR,
			payload: { type: 'get_consumable_info', msg: err.message }
		});

		dispatch({
			type: CONSUMABLE_INFO_LOADING,
			payload: false
		});
	})
}

export const consumable_update = (messageApi, consumableId, consumableBody, handleCancel) => dispatch => {
	dispatch({
		type: CONSUMABLE_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/consumables/${consumableId}/update`;

	Axios.put(url, consumableBody)
	.then((res) => {
		messageApi.success(i18next.t('consumables.edit.successAlert'), 4);

		dispatch({
			type: CONSUMABLE_UPDATE_LOADING,
			payload: false
		});
		
		handleCancel();
	}).catch((err) => {
		// console.log(err.response.status);
		if (err.response.status === 400) {
			handleCancel();
		}
		else {
			dispatch({
				type: CONSUMABLE_UPDATE_ERROR,
				payload: { type: 'consumable_update', msg: err.message }
			});
		}

		dispatch({
			type: CONSUMABLE_UPDATE_LOADING,
			payload: false
		});
	})
}

export const consumable_status_update = (messageApi, consumableId, status) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/game/consumables/${consumableId}/status/update`;

	Axios.put(url, { status });
}

export const consumable_delete = (messageApi, consumableId, filters) => dispatch => {
	dispatch({
		type: CONSUMABLE_DELETE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/game/consumables/${consumableId}/remove`;

	Axios.delete(url)
	.then((res) => {
		messageApi.success(i18next.t('consumables.delete.successAlert'), 4);

		dispatch({
			type: CONSUMABLE_DELETE_LOADING,
			payload: false
		});

		dispatch(get_all_consumables(filters));
	}).catch((err) => {
		// console.log(err);
		messageApi.error(i18next.t('consumables.delete.errorAlert'), 4);

		dispatch({
			type: CONSUMABLE_DELETE_LOADING,
			payload: false
		});
	})
}
