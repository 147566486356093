import React from 'react';

// Packages
import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';

const BooleanIcon = (props) => {
	const { value } = props;

	return (
		<div>
			{value === true ? (
				<CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: '1.2rem' }} />
			) : value === false ? (
				<CloseCircleTwoTone twoToneColor='#f5222d' style={{ fontSize: '1.2rem' }} />
			) : (
				<QuestionCircleTwoTone twoToneColor='#bfbfbf' style={{ fontSize: '1.2rem' }} />
			)}
		</div>
	);
};

BooleanIcon.propTypes = {
	value: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])])
};

export default BooleanIcon;
