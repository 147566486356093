import i18next from 'i18next';

// RECIPE_TYPE_NONE = 0
// RECIPE_TYPE_EMPTY = 1
// RECIPE_TYPE_BOTH = 2
// RECIPE_TYPE_CONTENT = 3
export const get_step_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                    break;
		case 1:   desc = i18next.t('statusDesc.stepTypes.empty');   break;
		case 2:   desc = i18next.t('statusDesc.stepTypes.both');    break;
		case 3:   desc = i18next.t('statusDesc.stepTypes.content'); break;
		default:  desc = 'None';                                    break;
	}

	return desc;
}

// HAPTIC_TYPE_DRAG = 0
// HAPTIC_TYPE_SLICE = 1
// HAPTIC_TYPE_TAP = 2
// HAPTIC_TYPE_SHAKE = 3
// HAPTIC_TYPE_DIRECTED_MOVE = 4
// HAPTIC_TYPE_ROTATE = 5
// HAPTIC_TYPE_FREEZE = 6
// HAPTIC_TYPE_INTERACT_WITH_OVERLAY = 7
export const get_haptic_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.haptictypes.drag');                break;
		case 1:   desc = i18next.t('statusDesc.haptictypes.slice');               break;
		case 2:   desc = i18next.t('statusDesc.haptictypes.tap');                 break;
		case 3:   desc = i18next.t('statusDesc.haptictypes.shake');               break;
		case 4:   desc = i18next.t('statusDesc.haptictypes.directedMove');        break;
		case 5:   desc = i18next.t('statusDesc.haptictypes.rotate');              break;
		case 6:   desc = i18next.t('statusDesc.haptictypes.freeze');              break;
		case 7:   desc = i18next.t('statusDesc.haptictypes.interactWithOverlay'); break;
		default:  desc = 'None';                                                  break;
	}

	return desc;
}

// HAPTIC_DRAG_ELEMENT_TYPE_INGREDIENT = 0
// HAPTIC_DRAG_ELEMENT_TYPE_ITEM = 1
export const get_haptic_drag_element_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticDragElementTypes.ingredient'); break;
		case 1:   desc = i18next.t('statusDesc.hapticDragElementTypes.item');       break;
		default:  desc = 'None';                                                    break;
	}

	return desc;
}

// SLICE_TYPE_LINEAR = 0
// SLICE_TYPE_CIRCULAR = 1
export const get_slice_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticSliceTypes.linear');   break;
		case 1:   desc = i18next.t('statusDesc.hapticSliceTypes.circular'); break;
		default:  desc = 'None';                                            break;
	}

	return desc;
}

// SLICE_CIRCULAR_SIZE_LARGE = 0
// SLICE_CIRCULAR_SIZE_MEDIUM = 1
export const get_slice_circular_size_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticSliceCircularSize.large');  break;
		case 1:   desc = i18next.t('statusDesc.hapticSliceCircularSize.medium'); break;
		default:  desc = 'None';                                                  break;
	}

	return desc;
}

// HAPTIC_ALIGNMENT_TOP_LEFT = 0
// HAPTIC_ALIGNMENT_TOP_CENTER = 1
// HAPTIC_ALIGNMENT_TOP_RIGHT = 2
// HAPTIC_ALIGNMENT_CENTER_LEFT = 3
// HAPTIC_ALIGNMENT_CENTER = 4
// HAPTIC_ALIGNMENT_CENTER_RIGHT = 5
// HAPTIC_ALIGNMENT_BOTTOM_LEFT = 6
// HAPTIC_ALIGNMENT_BOTTOM_CENTER = 7
// HAPTIC_ALIGNMENT_BOTTOM_RIGHT = 8
export const get_haptic_alignment_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticAlignments.topLeft');      break;
		case 1:   desc = i18next.t('statusDesc.hapticAlignments.topCenter');    break;
		case 2:   desc = i18next.t('statusDesc.hapticAlignments.topRight');     break;
		case 3:   desc = i18next.t('statusDesc.hapticAlignments.centerLeft');   break;
		case 4:   desc = i18next.t('statusDesc.hapticAlignments.center');       break;
		case 5:   desc = i18next.t('statusDesc.hapticAlignments.centerRight');  break;
		case 6:   desc = i18next.t('statusDesc.hapticAlignments.bottomLeft');   break;
		case 7:   desc = i18next.t('statusDesc.hapticAlignments.bottomCenter'); break;
		case 8:   desc = i18next.t('statusDesc.hapticAlignments.bottomRight');  break;
		default:  desc = 'None';                                                break;
	}

	return desc;
}

// HAPTIC_MAGNITUDE_LOW = 0
// HAPTIC_MAGNITUDE_NORMAL = 1
// HAPTIC_MAGNITUDE_HARD = 2
export const get_haptic_magnitude_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticMagnitudes.low');    break;
		case 1:   desc = i18next.t('statusDesc.hapticMagnitudes.normal'); break;
		case 2:   desc = i18next.t('statusDesc.hapticMagnitudes.hard');   break;
		default:  desc = 'None';                                          break;
	}

	return desc;
}

// HAPTIC_SPEED_RATE_SLOW = 0
// HAPTIC_SPEED_RATE_MEDIUM = 1
// HAPTIC_SPEED_RATE_FAST = 2
export const get_haptic_speed_rate_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticSpeedRates.slow');   break;
		case 1:   desc = i18next.t('statusDesc.hapticSpeedRates.medium'); break;
		case 2:   desc = i18next.t('statusDesc.hapticSpeedRates.fast');   break;
		default:  desc = 'None';                                          break;
	}

	return desc;
}

// AXIS_HORIZONTAL = 0
// AXIS_VERTICAL = 1
export const get_haptic_axis_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticsAxis.horizontal'); break;
		case 1:   desc = i18next.t('statusDesc.hapticsAxis.vertical');   break;
		default:  desc = 'None';                                         break;
	}

	return desc;
}

// AXIS_DIRECTION_UP = 0
// AXIS_DIRECTION_RIGHT = 1
// AXIS_DIRECTION_DOWN = 2
// AXIS_DIRECTION_LEFT = 3
export const get_haptic_axis_direction_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.hapticsAxisDirections.up');    break;
		case 1:   desc = i18next.t('statusDesc.hapticsAxisDirections.right'); break;
		case 2:   desc = i18next.t('statusDesc.hapticsAxisDirections.down');  break;
		case 3:   desc = i18next.t('statusDesc.hapticsAxisDirections.left');  break;
		default:  desc = 'None';                                              break;
	}

	return desc;
}

// ITEM_TYPE_NONE = 0
// ITEM_TYPE_FILLABLE = 1
// ITEM_TYPE_HOLDABLE = 2
export const get_item_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                     break;
		case 1:   desc = i18next.t('statusDesc.itemTypes.fillable'); break;
		case 2:   desc = i18next.t('statusDesc.itemTypes.holdable'); break;
		default:  desc = 'None';                                     break;
	}

	return desc;
}

// EQUIPMENT_OPTIONS_NONE
// EQUIPMENT_OPTIONS_STOVE
// EQUIPMENT_OPTIONS_OVEN
// EQUIPMENT_OPTIONS_MICROWAVE
// EQUIPMENT_OPTIONS_FRIDGE
// EQUIPMENT_OPTIONS_DISHWASHER
// EQUIPMENT_OPTIONS_TABLE
// EQUIPMENT_OPTIONS_PLATE
export const get_equipment_options_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                              break;
		case 1:   desc = i18next.t('statusDesc.equipmentOptions.stove');      break;
		case 2:   desc = i18next.t('statusDesc.equipmentOptions.oven');       break;
		case 3:   desc = i18next.t('statusDesc.equipmentOptions.microwave');  break;
		case 4:   desc = i18next.t('statusDesc.equipmentOptions.fridge');     break;
		case 5:   desc = i18next.t('statusDesc.equipmentOptions.dishwasher'); break;
		case 6:   desc = i18next.t('statusDesc.equipmentOptions.table');      break;
		case 7:   desc = i18next.t('statusDesc.equipmentOptions.plate');      break;
		default:  desc = 'None';                                              break;
	}

	return desc;
}

// MEDAL_TYPE_TIME = 1
// MEDAL_TYPE_WORLD = 2
// MEDAL_TYPE_CHALLENGE = 3
// MEDAL_TYPE_RECIPE = 4
// MEDAL_TYPE_SCORE = 5
export const get_medal_types_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                       break;
		case 1:   desc = i18next.t('statusDesc.medalTypes.time');      break;
		case 2:   desc = i18next.t('statusDesc.medalTypes.world');     break;
		case 3:   desc = i18next.t('statusDesc.medalTypes.challenge'); break;
		case 4:   desc = i18next.t('statusDesc.medalTypes.recipe');    break;
		case 5:   desc = i18next.t('statusDesc.medalTypes.score');     break;
		default:  desc = 'None';                                       break;
	}

	return desc;
}

// CHALLENGE_TYPE_TIME = 1
// CHALLENGE_TYPE_HAPTIC = 2
// CHALLENGE_TYPE_VALUE = 3
// CHALLENGE_TYPE_ORDER = 4
export const get_challenge_types_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                        break;
		case 1:   desc = i18next.t('statusDesc.challengeTypes.time');   break;
		case 2:   desc = i18next.t('statusDesc.challengeTypes.haptic'); break;
		case 3:   desc = i18next.t('statusDesc.challengeTypes.value');  break;
		case 4:   desc = i18next.t('statusDesc.challengeTypes.order');  break;
		default:  desc = 'None';                                        break;
	}

	return desc;
}

// RECIPE_STATUS_NONE = 0
// RECIPE_STATUS_AVAILABLE = 1
// RECIPE_STATUS_COMING_SOON = 2
// RECIPE_STATUS_DISABLED = 3
export const get_recipe_status_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                    break;
		case 1:   desc = i18next.t('statusDesc.status.available');  break;
		case 2:   desc = i18next.t('statusDesc.status.comingSoon'); break;
		case 3:   desc = i18next.t('statusDesc.status.disabled');   break;
		default:  desc = 'None';                                    break;
	}

	return desc;
}

// LEVEL_STATUS_AVAILABLE = 1
// LEVEL_STATUS_BLOCKED = 2
// LEVEL_STATUS_PLAYED = 3
export const get_level_status_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                   break;
		case 1:   desc = i18next.t('statusDesc.status.available'); break;
		case 2:   desc = i18next.t('statusDesc.status.blocked');   break;
		case 3:   desc = i18next.t('statusDesc.status.played');    break;
		default:  desc = 'None';                                   break;
	}

	return desc;
}

// PROPERTIES_MATTER_NONE = 0
// PROPERTIES_MATTER_SOLID = 1
// PROPERTIES_MATTER_LIQUID = 2
// PROPERTIES_MATTER_GAS = 3
// PROPERTIES_MATTER_OTHER = 4
export const get_properties_matter_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                          break;
		case 1:   desc = i18next.t('statusDesc.propertiesMatter.solid');  break;
		case 2:   desc = i18next.t('statusDesc.propertiesMatter.liquid'); break;
		case 3:   desc = i18next.t('statusDesc.propertiesMatter.gas');    break;
		case 4:   desc = i18next.t('statusDesc.propertiesMatter.other');  break;
		default:  desc = 'None';                                          break;
	}

	return desc;
}

// ITEM_USAGE_TOP = 0
// ITEM_USAGE_GLASS = 1
export const get_item_usage_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.itemUsage.top');   break;
		case 1:   desc = i18next.t('statusDesc.itemUsage.glass'); break;
		default:  desc = 'None';                                  break;
	}

	return desc;
}

// INGREDIENT_TYPE_NORMAL = 0
// INGREDIENT_TYPE_COMPOUND = 1
export const get_ingredient_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.ingredientType.normal');   break;
		case 1:   desc = i18next.t('statusDesc.ingredientType.compound'); break;
		default:  desc = 'None';                                          break;
	}

	return desc;
}

// CONSUMABLE_TYPE_NONE = 0
// CONSUMABLE_TYPE_BEAN = 1
export const get_consumable_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.consumableType.none'); break;
		case 1:   desc = i18next.t('statusDesc.consumableType.bean'); break;
		default:  desc = 'None';                                      break;
	}

	return desc;
}
