import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import recipesReducer from './recipesReducer';
import ingredientsReducer from './ingredientsReducer';
import itemsReducer from './itemsReducer';
import worldsReducer from './worldsReducer';
import medalsReducer from './medalsReducer';
import statesReducer from './statesReducer';
import unitsReducer from './unitsReducer';
import propertiesReducer from './propertiesReducer';
import stepsReducer from './stepsReducer';
import actionsReducer from './actionsReducer';
import typesReducer from './typesReducer';
import hapticsReducer from './hapticsReducer';
import rulesReducer from './rulesReducer';
import challengesReducer from './challengesReducer';
import levelsReducer from './levelsReducer';
import categoriesReducer from './categoriesReducer';
import packagesReducer from './packagesReducer';
import difficultiesReducer from './difficultiesReducer';
import consumablesReducer from './consumablesReducer';

export default combineReducers ({
	auth: authReducer,
	users: usersReducer,
	recipes: recipesReducer,
	ingredients: ingredientsReducer,
	items: itemsReducer,
	worlds: worldsReducer,
	medals: medalsReducer,
	states: statesReducer,
	units: unitsReducer,
	properties: propertiesReducer,
	steps: stepsReducer,
	actions: actionsReducer,
	types: typesReducer,
	haptics: hapticsReducer,
	rules: rulesReducer,
	challenges: challengesReducer,
	levels: levelsReducer,
	categories: categoriesReducer,
	packages: packagesReducer,
	difficulties: difficultiesReducer,
	consumables: consumablesReducer
});
