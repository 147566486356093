import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { DeleteFilled, EyeFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_recipes, recipe_delete } from '../../../redux/actions/recipesActions';

// Utils
import { get_recipe_status_desc } from '../../../utils/get_status_desc';
import { get_recipe_status_color } from '../../../utils/get_status_color';

const RecipesTable = (props) => {
	const { messageApi, pageSize, setPageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { all_recipes_loading, all_recipes, recipe_delete_loading } = useSelector(state => state.recipes);

	const deleteRecipe = (record) => {
		let newSkip = skip;

		if ((all_recipes.count - 1) % pageSize === 0) {
			newSkip -= pageSize;
			setSkip(newSkip);
			localStorage.setItem('recipesSkip', newSkip);
		}

		const filters = { skip: newSkip, limit: pageSize };
		dispatch(recipe_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			key: 'name',
			title: t('form.label.name'),
			dataIndex: 'name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{record.name}
				</Typography.Text>
			)
		},
		{
			key: 'description',
			title: t('form.label.description'),
			dataIndex: 'description'
		},
		{
			key: 'name',
			title: t('form.label.state'),
			dataIndex: 'status',
			render: (_, record) => (
				<Tag color={get_recipe_status_color(record?.status)}>{get_recipe_status_desc(record?.status)}</Tag>
			)
		},
		{
			key: 'action',
			title: t('table.headers.actions'),
			dataIndex: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('buttons.details')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<EyeFilled />}
							onClick={() => navigate(`/recipes/${record.key}/details`)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('recipes.delete.confirmTitle')}
						description={t('recipes.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteRecipe(record)}
						okButtonProps={{
							loading: recipe_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<DeleteFilled />}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pagination.pageSize;
		setSkip(skip);
		localStorage.setItem('recipesSkip', skip);

		const limit = pagination.pageSize;
		setPageSize(limit);
		localStorage.setItem('recipesPageSize', limit);

		const recipesFilters = { skip, limit };
		dispatch(get_all_recipes(recipesFilters));
	};

	return (
		<Spin spinning={all_recipes_loading}>
			<Table
				columns={columns}
				dataSource={all_recipes.recipes}
				onChange={handleTableChange}
				scroll={{
					x: 'auto'
				}}
				pagination={{
					pageSize: pageSize, 
					total: all_recipes.count,
					current: (skip / pageSize) + 1,	
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('table.pagination.of')} ${total}`,
					showTitle: false,
					responsive: true,
					locale: { items_per_page: `/ ${t('table.pagination.page')}` }
				}}
			/>
		</Spin>
	)
}

RecipesTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default RecipesTable;
