import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Checkbox, Form, Input, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';
import SelectTags from '../../utils/SelectTags';

// Actions
import { get_all_packages, set_package_create_id, package_create, package_update, package_status_update } from '../../../redux/actions/packagesActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';
import { get_all_recipes } from '../../../redux/actions/recipesActions';
import { get_all_worlds } from '../../../redux/actions/worldsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { create_select_options, create_select_options_from_array } from '../../../utils/create_select_options';
import { recipe_status_options } from '../../../utils/select_options';

const PackageForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, packageToUpdate, setPackageToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { package_create_loading, package_id, package_update_loading } = useSelector(state => state.packages);

	const { all_worlds_loading, all_worlds } = useSelector(state => state.worlds);

	const { all_recipes_loading, all_recipes } = useSelector(state => state.recipes);

	const [fileList, setFileList] = useState([]);
	const [content, setContent] = useState(null);
	const [recipeStatusOptions, setRecipeStatusOptions] = useState([]);
	const [packageStatus, setPackageStatus] = useState(null);
	
	const [form] = Form.useForm();

	const filters = { skip: 0, limit: 0 };

	const contentData = {
		0: () => dispatch(get_all_worlds(filters)),
		1: () => dispatch(get_all_recipes(filters))
	}

	const RECIPE_STATUS_OPTIONS = recipe_status_options();

	useEffect(() => {
		setRecipeStatusOptions(create_select_options_from_array(RECIPE_STATUS_OPTIONS));
	}, []);

	useEffect(() => {
		if (content !== null) {
			contentData[content]();
		}
	}, [content]);

	useEffect(() => {
		if (!isEmpty(packageToUpdate)) {
			form.setFieldsValue({
				name: packageToUpdate.name,
				description: packageToUpdate.description,
				content: packageToUpdate.worlds.length > 0 ? 0 : 1,
				status: packageToUpdate.status,
				star: packageToUpdate.star,
				featured: packageToUpdate.featured,
				value: packageToUpdate.value,
				worlds: packageToUpdate.worlds.map(world => world?._id?.$oid),
				recipes: packageToUpdate.recipes.map(recipe => recipe?._id?.$oid)
			});

			if (packageToUpdate.worlds.length > 0) {
				setContent(0);
			} else if (packageToUpdate.recipes.length > 0) {
				setContent(1);
			} else {
				setContent(null);
			}

			setPackageStatus(packageToUpdate.status);
		}
	}, [packageToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setIsModalOpen(false);
		dispatch(set_package_create_id(null));
		setContent(null);
		setPackageToUpdate({});

		if (finish) {
			const filters = { skip: skip, limit: pageSize };
			dispatch(get_all_packages(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					const body = {
						name: values.name,
						description: values.description,
						status: values.status,
						star: values.star,
						featured: values.featured,
						value: values.value,
						worlds: values.worlds || [],
						recipes: values.recipes || []
					}

					dispatch(package_create(messageApi, body));
				}
				else {
					// Update
					const body = {
						name: values.name,
						description: values.description,
						star: values.star,
						featured: values.featured,
						value: values.value,
						worlds: values.worlds || [],
						recipes: values.recipes || []
					}

					dispatch(package_update(messageApi, package_id, body, () => handleCancel(true)));

					if (packageStatus !== packageToUpdate.status) {
						dispatch(package_status_update(messageApi, package_id, packageStatus));
					}
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('packages.create.modalTitle') : t('packages.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? package_create_loading : package_update_loading}
					onClick={package_id && !update ? () => handleCancel(true) : onOk}
				>
					{package_id ? t('buttons.finish') : t('buttons.save')}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
				initialValues={{ star: false, featured: false, worlds: [], recipes: [] }}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				{update && (
					<Form.Item
						label={t('form.label.state')}
						name='status'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							placeholder={`${t('form.placeholders.select')} ${t('form.label.state')}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={recipeStatusOptions}
							onChange={(value) => setPackageStatus(value)}
						/>
					</Form.Item>
				)}
				<Form.Item
					label={t('form.label.star')}
					name='star'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label={t('form.label.featured')}
					name='featured'
					valuePropName='checked'
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label={t('form.label.value')}
					name='value'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={`${t('form.placeholders.enter')} ${t('form.label.value')}`} />
				</Form.Item>
				<Form.Item
					label={t('form.label.content')}
					name='content'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.content')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={[
							{ label: t('form.label.world'), value: 0 },
							{ label: t('menuItems.recipes').slice(0, -1), value: 1 },
						]}
						onChange={(value) => setContent(value)}
					/>
				</Form.Item>
				{content === 0 && (
					<Form.Item
						label={t('menuItems.game.worlds')}
						name='worlds'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							mode='multiple'
							placeholder={`${t('form.placeholders.select')} ${t('menuItems.game.worlds')}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							tagRender={SelectTags}
							options={create_select_options(all_worlds.worlds, 'name')}
						/>
					</Form.Item>
				)}
				{content === 1 && (
					<Form.Item
						label={t('menuItems.recipes')}
						name='recipes'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							mode='multiple'
							placeholder={`${t('form.placeholders.select')} ${t('menuItems.recipes')}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							tagRender={SelectTags}
							options={create_select_options(all_recipes.recipes, 'name')}
						/>
					</Form.Item>
				)}
			</Form>
			{package_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '5.4rem' }}>Asset:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/recipes/packs/${package_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
				</Space>
			)}
		</Modal>
	)
}

PackageForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	packageToUpdate: PropTypes.object.isRequired,
	setPackageToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default PackageForm;
