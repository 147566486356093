import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, ColorPicker, Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_worlds, set_world_create_id, world_create, world_update } from '../../../redux/actions/worldsActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { format_hex_color_with_alpha, transform_color_format } from '../../../utils/colors_handlers';

const WorldForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, worldToUpdate, setWorldToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { world_create_loading, world_id, world_update_loading } = useSelector(state => state.worlds);

	const [fileList, setFileList] = useState([]);
	const [patternFile, setPatternFile] = useState([]);
	
	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(worldToUpdate)) {
			form.setFieldsValue({
				name: worldToUpdate?.name,
				description: worldToUpdate?.description,
				nationality: worldToUpdate?.nationality,
				color: transform_color_format(worldToUpdate?.color),
			});
		}
	}, [worldToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setPatternFile([]);
		setIsModalOpen(false);
		setWorldToUpdate({});
		dispatch(set_world_create_id(null));

		if (finish) {
			const filters = { skip: skip, limit: pageSize };
			dispatch(get_all_worlds(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				const hexString = typeof values.color === 'string' ? values.color : values.color?.toHex();
				values.color = format_hex_color_with_alpha(hexString);

				if (!update) {
					// Create
					dispatch(world_create(values));
				}
				else {
					// Update
					dispatch(world_update(messageApi, world_id, values, () => handleCancel(true)));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('worlds.create.modalTitle') : t('worlds.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? world_create_loading : world_update_loading}
					onClick={world_id && !update ? () => handleCancel(true) : onOk}
				>
					{world_id ? t('buttons.finish') : t('buttons.save')}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
				initialValues={{
					color: worldToUpdate.color !== undefined ? transform_color_format(worldToUpdate.color) : undefined
				}}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.nationality')}
					name='nationality'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.nationality')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.color')}
					name='color'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<ColorPicker format='hex' />
				</Form.Item>
			</Form>
			{world_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '4.7rem' }}>{t('form.label.pattern')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/game/worlds/${world_id}/pattern/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={patternFile}
						setFileList={setPatternFile}
					/>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/game/worlds/${world_id}/image/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={fileList}
						setFileList={setFileList}
					/>
				</Space>
			)}
		</Modal>
	)
}

WorldForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	worldToUpdate: PropTypes.object.isRequired,
	setWorldToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default WorldForm;
