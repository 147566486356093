import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { get_all_consumables, set_consumable_create_id, consumable_create, consumable_update, consumable_status_update } from '../../../redux/actions/consumablesActions';
import { form_image_upload } from '../../../redux/actions/utilsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { create_haptic_select_options, create_select_options_from_array } from '../../../utils/create_select_options';
import { consumable_types_options, recipe_status_options } from '../../../utils/select_options';

const ConsumableForm = (props) => {
	const { messageApi, isModalOpen, setIsModalOpen, update, consumableToUpdate, setConsumableToUpdate, pageSize, skip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { consumable_create_loading, consumable_id, consumable_update_loading } = useSelector(state => state.consumables);

	const [fileList, setFileList] = useState([]);
	const [assetFile, setAssetFile] = useState([]);
	const [consumableTypes, setConsumableTypes] = useState([]);
	const [consumableStatus, setConsumableStatus] = useState(null);
	const [consumableStatusOptions, setConsumableStatusOptions] = useState([]);
	
	const [form] = Form.useForm();

	useEffect(() => {
		// Using create_haptic_select_options to consider the first option.
		setConsumableTypes(create_haptic_select_options(consumable_types_options()));

		setConsumableStatusOptions(create_select_options_from_array(recipe_status_options()));
	}, []);

	useEffect(() => {
		if (!isEmpty(consumableToUpdate)) {
			form.setFieldsValue(consumableToUpdate);
		}
	}, [consumableToUpdate]);

	const handleCancel = (finish) => {
		form.resetFields();
		setFileList([]);
		setAssetFile([]);
		setIsModalOpen(false);
		setConsumableToUpdate({});
		dispatch(set_consumable_create_id(null));
		setConsumableStatus(null);

		if (finish) {
			const filters = { skip: skip, limit: pageSize };
			dispatch(get_all_consumables(filters));
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				if (!update) {
					// Create
					dispatch(consumable_create(values));
				}
				else {
					// Update
					dispatch(consumable_update(messageApi, consumable_id, values, () => handleCancel(true)));

					if (consumableToUpdate?.status !== consumableStatus) {
						// Update status
						dispatch(consumable_status_update(messageApi, consumable_id, consumableStatus));
					}
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Modal
			title={!update ? t('consumables.create.modalTitle') : t('consumables.edit.modalTitle')}
			centered
			open={isModalOpen}
			onCancel={() => handleCancel(false)}
			maskClosable={false}
			width={700}
			footer={[
				<Button
					key='submit'
					type='primary'
					loading={!update ? consumable_create_loading : consumable_update_loading}
					onClick={consumable_id && !update ? () => handleCancel(true) : onOk}
				>
					{consumable_id ? t('buttons.finish') : t('buttons.save')}
				</Button>,
			]}
		>
			<Form
				labelCol={{ span: 5 }}
				form={form}
			>
				<Form.Item
					label={t('form.label.name')}
					name='name'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.name')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.description')}
					name='description'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={t('form.placeholders.description')} />
				</Form.Item>
				{update && (
					<Form.Item
						label={t('form.label.state')}
						name='status'
						rules={[
							{
								required: true,
								message: t('form.rules.required'),
							}
						]}
					>
						<Select
							showSearch
							placeholder={`${t('form.placeholders.select')} ${t('form.label.state')}`}
							optionFilterProp='children'
							filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
							options={consumableStatusOptions}
							onChange={(value) => setConsumableStatus(value)}
						/>
					</Form.Item>
				)}
				<Form.Item
					label={t('form.label.type')}
					name='consumable_type'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Select
						showSearch
						placeholder={`${t('form.placeholders.select')} ${t('form.label.type')}`}
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={consumableTypes}
					/>
				</Form.Item>
				<Form.Item
					label={t('form.label.quantity')}
					name='quantity'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<InputNumber placeholder={t('form.placeholders.quantity')} />
				</Form.Item>
				<Form.Item
					label={t('form.label.value')}
					name='value'
					rules={[
						{
							required: true,
							message: t('form.rules.required'),
						}
					]}
				>
					<Input placeholder={`${t('form.placeholders.enter')} ${t('form.label.value')}`} />
				</Form.Item>
			</Form>
			{consumable_id && (
				<Space align='start' size={10}>
					<Typography.Text style={{ marginLeft: '4.3rem' }}>{t('form.label.image')}:</Typography.Text>
					<ImageUpload
						action={process.env.REACT_APP_SERVER_URL + `/api/game/consumables/${consumable_id}/asset/upload`}
						actionFunction={(options) => dispatch(form_image_upload(options))}
						fileList={assetFile}
						setFileList={setAssetFile}
					/>
				</Space>
			)}
		</Modal>
	)
}

ConsumableForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	update: PropTypes.bool.isRequired,
	consumableToUpdate: PropTypes.object.isRequired,
	setConsumableToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired
}

export default ConsumableForm;
