import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Badge, Button, Card, Col, Flex, Popconfirm, Row, Space, Tooltip, Typography } from 'antd';
import { DeleteFilled, EditFilled, QuestionCircleFilled, TrophyFilled } from '@ant-design/icons';
import Axios from 'axios';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Components
import ImageRender from '../../utils/ImageRender';
import Icon from '../../utils/Icon';

// Actions
import { is_step_game_modal_open, set_step_info, step_delete } from '../../../redux/actions/stepsActions';
import { get_all_states } from '../../../redux/actions/statesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { get_equipment_options_desc, get_step_type_desc } from '../../../utils/get_status_desc';

const StepInfo = (props) => {
	const { messageApi, recipeData, stepIndex, stepId, localSteps, setUpdateStep, setStepToUpdate, hasUpdates } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { step_delete_loading } = useSelector(state => state.steps);

	const [stepInfo, setStepInfo] = useState({});
	const [stepTypeIcon, setStepTypeIcon] = useState(null);
	const [stepHasRule, setStepHasRule] = useState(false);
	const [stepHasChallenge, setStepHasChallenge] = useState(false);

	useEffect(() => {
		let isMounted = true;

		Axios.get(process.env.REACT_APP_SERVER_URL + `/api/recipes/steps/${stepId}/info`)
		.then((response) => {
			if (isMounted) {
				setStepInfo(response.data);
			}
		})
		.catch(() => {
			if (isMounted) {
				setStepInfo({});
			}
		});

		Axios.get(process.env.REACT_APP_SERVER_URL + `/api/recipes/rules?step=${stepId}`)
		.then((response) => {
			if (isMounted) {
				if (response.data.rules.length > 0) setStepHasRule(true);
			}
		})
		.catch(() => {
			if (isMounted) {
				setStepHasRule(false);
			}
		});

		Axios.get(process.env.REACT_APP_SERVER_URL + `/api/game/challenges?step=${stepId}`)
		.then((response) => {
			if (isMounted) {
				if (response.data.challenges.length > 0) setStepHasChallenge(true);
			}
		})
		.catch(() => {
			if (isMounted) {
				setStepHasChallenge(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [stepId]);

	useEffect(() => {
		if (hasUpdates) {
			Axios.get(process.env.REACT_APP_SERVER_URL + `/api/recipes/rules?step=${stepId}`)
			.then((response) => {
				if (response.data.rules.length > 0) {
					setStepHasRule(true);
				}
				else {
					setStepHasRule(false);
				}
			})
			.catch(() => {
				setStepHasRule(false);
			});
	
			Axios.get(process.env.REACT_APP_SERVER_URL + `/api/game/challenges?step=${stepId}`)
			.then((response) => {
				if (response.data.challenges.length > 0) {
					setStepHasChallenge(true);
				}
				else {
					setStepHasChallenge(false);
				}
			})
			.catch(() => {
				setStepHasChallenge(false);
			});
		}
	}, [hasUpdates]);

	useEffect(() => {
		if (!isEmpty(stepInfo)) {
			if (stepInfo.recipe_type === 1) {
				setStepTypeIcon('🎮');
			} else if (stepInfo.recipe_type === 2) {
				setStepTypeIcon('🎮 / 📚');
			}
			else {
				setStepTypeIcon('📚');
			}
		}
	}, [stepInfo]);	

	const setIngredientQuantityType = (ingredientId) => {
		const ingredient = recipeData?.ingredients?.find(i => i.ingredient._id.$oid === ingredientId);
		if (ingredient) {
			return ` ${ingredient.properties.unit.abbreviation}`;
		}

		return '';
	}

	const updateStep = (stepInfo) => {
		setUpdateStep(true);
		stepInfo.index++;
		setStepToUpdate(stepInfo);
		dispatch(get_all_states({ skip: 0, limit: 0 }));
	}

	const deleteStep = () => {
		dispatch(step_delete(messageApi, stepInfo?._id?.$oid, { recipe: stepInfo?.recipe?.$oid }, localSteps));
	}

	return (
		<div>
			<Card
				bordered={false}
				loading={isEmpty(stepInfo)}
			>
				<Flex
					align='center'
					justify='flex-start'
				>
					<Row align='middle' style={{ width: '100%' }}>
						<Col xs={24} sm={1} md={1} lg={1} xl={1}>
							<Typography.Text>{stepIndex}</Typography.Text>
						</Col>
						<Col xs={24} sm={24} md={3} lg={3} xl={3}>
							{!isEmpty(stepInfo)
								?	<ImageRender
										key={stepInfo.image}
										url={`${process.env.REACT_APP_SERVER_URL}/api/recipes/steps/${stepInfo?._id?.$oid}/image`}
										size={{ width: 100, height: 100 }}
									/>
								:	null
							}
						</Col>
						<Col xs={24} sm={24} md={17} lg={17} xl={17}>
							<Space direction='vertical'>
								<Typography.Text strong copyable={{ text: stepInfo?._id?.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
									{stepTypeIcon} {stepInfo.name}
								</Typography.Text>
								<Typography.Paragraph style={{ margin: 0 }}>
									<Typography.Text strong>{t('form.label.instruction')}:</Typography.Text> {stepInfo.description}
								</Typography.Paragraph>
							</Space>
							<Row style={{ marginTop: '1rem' }} gutter={[16, 16]}>
								<Col xs={24} sm={24} md={24} lg={8} xl={8}>
									<Space direction='vertical'>
										<Typography.Text strong>{t('menuItems.ingredients.ingredients')}:</Typography.Text>
										{stepInfo.cooking_process?.length > 0
											?	<ul style={{ marginTop: 0 }}>
													{stepInfo.cooking_process?.map((ingredient, index) => (
														<li key={index}>
															{ingredient?.start?.ingredient?.name} ({ingredient?.quantity}{setIngredientQuantityType(ingredient?.start?.ingredient?._id?.$oid)}): {ingredient?.start?.name} <Icon icon='trending_flat' size={14} sider /> {ingredient?.end?.name}
														</li>
													))}
												</ul>
											: <Typography.Text>{t('table.error.noData')}</Typography.Text>
										}
									</Space>
								</Col>
								<Col xs={24} sm={24} md={24} lg={8} xl={8}>
									<Space direction='vertical'>
										<Typography.Text strong>{t('menuItems.items')}:</Typography.Text>
										{stepInfo.items?.length > 0
											?	<ul style={{ marginTop: 0 }}>
													{stepInfo.items?.map((item, index) => (
														<li key={index}>{item.name}</li>
													))}
												</ul>
											:	<Typography.Text>{t('table.error.noData')}</Typography.Text>
										}
									</Space>
								</Col>
								<Col xs={24} sm={24} md={24} lg={8} xl={8}>
									<Space direction='vertical'>
										<Typography.Text strong>{t('form.label.equipment')}:</Typography.Text>
										{stepInfo.equipment
											?	<ul style={{ marginTop: 0 }}>
													<li>{get_equipment_options_desc(stepInfo.equipment)}</li>
												</ul>
											:	<Typography.Text>{t('table.error.noData')}</Typography.Text>
										}
									</Space>
								</Col>
							</Row>
							<Row style={{ marginTop: '1rem' }} gutter={[16, 16]}>
								{!isEmpty(stepInfo?.ingredients_mix) &&
									<Col xs={24} sm={24} md={24} lg={8} xl={8}>
										<Space direction='vertical'>
											<Typography.Text strong>{t('steps.form.finalIngredient')}:</Typography.Text>
												<ul style={{ marginTop: 0 }}>
													<li>
														{stepInfo?.ingredients_mix?.ingredient?.name}: {stepInfo?.ingredients_mix?.name}
													</li>
												</ul>
										</Space>
									</Col>
								}
								{!isEmpty(stepInfo?.go_to_item) &&
									<Col xs={24} sm={24} md={24} lg={8} xl={8}>
										<Space direction='vertical'>
											<Typography.Text strong>{t('form.label.resultGoesToBowl')}:</Typography.Text>
												<ul style={{ marginTop: 0 }}>
													<li>
														{stepInfo?.go_to_item?.name}
													</li>
												</ul>
										</Space>
									</Col>
								}
							</Row>
						</Col>
						<Col xs={24} sm={3} md={3} lg={3} xl={3}>
							<Flex justify='space-evenly'>
								<Tooltip title={`${t('challenges.title')}s`}>
									<Button
										type='text'
										shape='circle'
										size='large'
										icon={
											<Badge dot={stepHasRule} offset={stepHasChallenge ? [-13, 25] : [-9, 25]} color='#F18B71'>
												<Badge dot={stepHasChallenge} offset={stepHasRule ? [-5, 25] : [-9, 25]} color='#80B4B9'>
													<TrophyFilled style={{ color: '#9E9E9E' }} />
												</Badge>
											</Badge>
										}
										onClick={() => {
											dispatch(is_step_game_modal_open(true));
											dispatch(set_step_info(stepInfo));
										}}
									/>
								</Tooltip>
								<Tooltip title={t('buttons.edit')}>
									<Button
										type='text'
										shape='circle'
										size='large'
										icon={<EditFilled style={{ color: '#9E9E9E' }} />}
										onClick={() => updateStep(stepInfo)}
									/>
								</Tooltip>
								<Popconfirm
									title={t('steps.delete.confirmTitle')}
									description={t('steps.delete.confirmText')}
									icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
									onConfirm={() => deleteStep()}
									okButtonProps={{
										loading: step_delete_loading
									}}
									okText={t('buttons.confirm')}
									cancelText={t('buttons.cancel')}
								>
									<Button
										type='text'
										shape='circle'
										size='large'
										icon={<DeleteFilled style={{ color: '#9E9E9E' }} />}
									/>
								</Popconfirm>
							</Flex>
						</Col>
					</Row>
				</Flex>
			</Card>
		</div>
	)
}

StepInfo.propTypes = {
	messageApi: PropTypes.object.isRequired,
	recipeData: PropTypes.object.isRequired,
	stepIndex: PropTypes.number.isRequired,
	stepId: PropTypes.string.isRequired,
	localSteps: PropTypes.array.isRequired,
	setUpdateStep: PropTypes.func.isRequired,
	setStepToUpdate: PropTypes.func.isRequired,
	hasUpdates: PropTypes.bool.isRequired
}

export default StepInfo;
