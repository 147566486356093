import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Card, Col, Descriptions, Divider, Empty, Flex, Popconfirm, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { DeleteFilled, EditFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_haptics, haptic_delete } from '../../../redux/actions/hapticsActions';

// Utils
import {
	get_haptic_alignment_desc, get_haptic_axis_desc, get_haptic_axis_direction_desc,
	get_haptic_drag_element_type_desc,get_haptic_magnitude_desc, get_haptic_speed_rate_desc,
	get_haptic_type_desc,
	get_slice_circular_size_desc,
	get_slice_type_desc
} from '../../../utils/get_status_desc';

const Haptics = (props) => {
	const { messageApi, typeId, setIsHapticModalOpen, setUpdateHaptic, setHapticToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { all_haptics_loading, all_haptics, haptic_delete_loading } = useSelector(state => state.haptics);

	const hapticFilters = { action: typeId };
	
	useEffect(() => {
		dispatch(get_all_haptics(hapticFilters));
	}, []);

	const setDescriptions = (haptic, hapticType) => {
		let descriptions = [];

		switch (hapticType) {
			case 0:
				descriptions = [
					{
						label: t('form.label.element'),
						children: get_haptic_drag_element_type_desc(haptic.drag?.element)
					},
					{
						label: t('form.label.destiny'),
						children: get_haptic_drag_element_type_desc(haptic.drag?.destiny)
					}
				];
			break;
			case 1:
				descriptions = [
					{
						label: t('form.label.nPointers'),
						children: haptic.slice?.n_pointers
					},
					{
						label: t('form.label.mirrored'),
						children: haptic.slice?.mirrored === true ? t('statusDesc.bool.true') : t('statusDesc.bool.false')
					},
					{
						label: t('form.label.sliceType'),
						children: get_slice_type_desc(haptic.slice?.slice_type)
					},
					...haptic.slice?.slice_type === 0 ? [
						{
							label: t('form.label.begin'),
							children: get_haptic_alignment_desc(haptic.slice?.begin)
						},
						{
							label: t('form.label.end'),
							children: get_haptic_alignment_desc(haptic.slice?.end)
						}
					] : [
						{
							label: t('form.label.circularSize'),
							children: get_slice_circular_size_desc(haptic.slice?.circular_size)
						}
					],
					{
						label: t('form.label.speedRate'),
						children: get_haptic_speed_rate_desc(haptic.slice?.speed_rate)
					}
				];
			break;
			case 2:
				descriptions = [
					{
						label: t('form.label.holdTime'),
						children: haptic.tap?.hold_time
					},
					{
						label: t('form.label.begin'),
						children: get_haptic_alignment_desc(haptic.tap?.begin)
					},
					{
						label: t('form.label.end'),
						children: get_haptic_alignment_desc(haptic.tap?.end)
					}
				];
			break;
			case 3:
				descriptions = [
					{
						label: t('form.label.magnitude'),
						children: get_haptic_magnitude_desc(haptic.shake?.magnitude)
					},
					{
						label: t('form.label.direction'),
						children: get_haptic_axis_desc(haptic.shake?.direction)
					}
				];
			break;
			case 4:
				descriptions = [
					{
						label: t('form.label.magnitude'),
						children: get_haptic_magnitude_desc(haptic.shake?.magnitude)
					},
					{
						label: t('form.label.direction'),
						children: get_haptic_axis_direction_desc(haptic.shake?.direction)
					}
				];
			break;
			case 5:
				descriptions = [
					{
						label: t('form.label.speedRate'),
						children: get_haptic_speed_rate_desc(haptic.slice?.speed_rate)
					}
				];
			break;
			default:
				descriptions = [];
		}
		
		descriptions.push({
			label: t('form.label.repeats'),
			children: haptic.repeats
		});
		
		return descriptions;
	}

	const updateHaptic = (haptic) => {
		setIsHapticModalOpen(true);
		setUpdateHaptic(true);
		setHapticToUpdate(haptic);
	}

	const deleteHaptic = (haptic) => {
		dispatch(haptic_delete(messageApi, haptic?._id?.$oid, hapticFilters));
	}

	return (
		<div>
			{all_haptics_loading
				?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
						<Spin size='large' />
					</div>
				:	all_haptics.count === 0
					?	<div style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
							<Empty description={<Typography.Text type='secondary'>{t('table.error.noData')}</Typography.Text>} />
						</div>
					:	<Space direction='vertical' size='large' style={{ display: 'flex' }}>
						{all_haptics.haptics.map((haptic, index) => (
							<Card bordered={false} key={index}>
								<Flex
									align='center'
									justify='flex-start'
								>
									<Row align='middle' style={{ width: '100%' }}>
										<Col xs={24} sm={24} md={22} lg={22} xl={22}>
											<Space direction='vertical'>
												<Typography.Text strong copyable={{ text: haptic._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
													{haptic.name}
												</Typography.Text>
												<Typography.Text>{haptic.description}</Typography.Text>
												<Divider />
												<Typography.Text strong></Typography.Text>
												<Descriptions
													title={`${t('form.label.hapticType')}: ${get_haptic_type_desc(haptic.haptic_type)}`}
													column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 4 }}
													items={setDescriptions(haptic, haptic.haptic_type)}
												/>
											</Space>
										</Col>
										<Col xs={24} sm={2} md={2} lg={2} xl={2}>
											<Space>
												<Tooltip title={t('buttons.edit')}>
													<Button
														type='text'
														shape='circle'
														size='large'
														icon={<EditFilled style={{ color: '#9E9E9E' }} />}
														onClick={() => updateHaptic(haptic)}
													/>
												</Tooltip>
												<Popconfirm
													title={t('actions.haptics.delete.confirmTitle')}
													description={t('actions.haptics.delete.confirmText')}
													icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
													onConfirm={() => deleteHaptic(haptic)}
													okButtonProps={{
														loading: haptic_delete_loading
													}}
													okText={t('buttons.confirm')}
													cancelText={t('buttons.cancel')}
												>
													<Button
														type='text'
														shape='circle'
														size='large'
														icon={<DeleteFilled style={{ color: '#9E9E9E' }} />}
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Flex>
							</Card>
						))}
					</Space>
			}
		</div>
	)
}

Haptics.propTypes = {
	messageApi: PropTypes.object.isRequired,
	typeId: PropTypes.string.isRequired,
	setIsHapticModalOpen: PropTypes.func.isRequired,
	setUpdateHaptic: PropTypes.func.isRequired,
	setHapticToUpdate: PropTypes.func.isRequired
}

export default Haptics;
