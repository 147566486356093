import {
	CLEAR_ALL_CONSUMABLES_DATA,
	CLEAR_ALL_CONSUMABLES_ERRORS,
	CLEAR_CONSUMABLES_SINGLE_ERROR,
	CONSUMABLE_CREATE_LOADING,
	SET_CONSUMABLE_ID,
	CONSUMABLE_CREATE_ERROR,
	ALL_CONSUMABLES_LOADING,
	ALL_CONSUMABLES_GET,
	ALL_CONSUMABLES_ERROR,
	CONSUMABLE_INFO_LOADING,
	CONSUMABLE_INFO_GET,
	CONSUMABLE_INFO_ERROR,
	CONSUMABLE_UPDATE_LOADING,
	CONSUMABLE_UPDATE_ERROR,
	CONSUMABLE_DELETE_LOADING
} from '../types';

const initialState = {
	consumable_create_loading: false,
	consumable_id: null,
	all_consumables_loading: false,
	all_consumables: { count: 0, consumables: [] },
	consumable_info_loading: false,
	consumable_info: {},
	consumable_update_loading: false,
	consumable_delete_loading: false,
	consumables_errors: {}
}

export default function consumablesReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_CONSUMABLES_DATA:
			return {
				...initialState
			}
		case CLEAR_ALL_CONSUMABLES_ERRORS:
			return {
				...state,
				consumables_errors: {}
			}
		case CLEAR_CONSUMABLES_SINGLE_ERROR:
			return {
				...state,
				consumables_errors: Object.keys(state.consumables_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.consumables_errors[key];
					}
					return acc;
				}, {})
			}
		case CONSUMABLE_CREATE_LOADING:
			return {
				...state,
				consumable_create_loading: action.payload
			}
		case SET_CONSUMABLE_ID:
			return {
				...state,
				consumable_id: action.payload
			}
		case ALL_CONSUMABLES_LOADING:
			return {
				...state,
				all_consumables_loading: action.payload
			}
		case ALL_CONSUMABLES_GET:
			return {
				...state,
				all_consumables: {
					count: action.payload.count,
					consumables: action.payload.consumables
				}
			}
		case CONSUMABLE_INFO_LOADING:
			return {
				...state,
				consumable_info_loading: action.payload
			}
		case CONSUMABLE_INFO_GET:
			return {
				...state,
				consumable_info: action.payload
			}
		case CONSUMABLE_UPDATE_LOADING:
			return {
				...state,
				consumable_update_loading: action.payload
			}
		case CONSUMABLE_DELETE_LOADING:
			return {
				...state,
				consumable_delete_loading: action.payload
			}
		case CONSUMABLE_CREATE_ERROR:
		case ALL_CONSUMABLES_ERROR:
		case CONSUMABLE_INFO_ERROR:
		case CONSUMABLE_UPDATE_ERROR:
			return {
				...state,
				consumables_errors: {
					...state.consumables_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
