import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// Packages
import { Image, Skeleton } from 'antd';
import Axios from 'axios';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';

// Components
import ImageUpload from '../../utils/ImageUpload';

// Actions
import { form_image_upload } from '../../../redux/actions/utilsActions';

const RecipeImageRender = (props) => {
	const { recipeInfo, fileList, setFileList } = props;

	const { recipe_id } = useParams();

	const dispatch = useDispatch();

	const [loadingRecipeImg, setLoadingRecipeImg] = useState(true);
	const [recipeImgSrc, setRecipeImgSrc] = useState(null);

	useEffect(() => {
		let isMounted = true;
		setLoadingRecipeImg(true);

		Axios.get(`${process.env.REACT_APP_SERVER_URL}/api/recipes/${recipe_id}/image`, {
			responseType: 'arraybuffer',
		})
		.then((response) => {
			if (isMounted) {
				const data = `data:${response.headers['content-type']};base64, ${Buffer.from(
					response.data,
					'binary'
				).toString('base64')}`;
				setLoadingRecipeImg(false);
				setRecipeImgSrc(data);
			}
		})
		.catch(() => {
			if (isMounted) {
				setLoadingRecipeImg(false);
			}
		});

		return () => {
			isMounted = false;
		};
	}, [recipeInfo]);

	return (
		<div
			style={{
				marginRight: '2rem',
				width: '150px',
				height: '150px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{loadingRecipeImg ? (
				<Skeleton.Image
					style={{ width: '150px', height: '150px' }}
					active={loadingRecipeImg}
				/>
			) : recipeInfo?.recipe?.image ? (
				<Image
					style={{
						height: '150px',
						width: '100%',
						maxWidth: '150px',
						objectFit: 'contain',
					}}
					src={recipeImgSrc}
				/>
			) : (
				<ImageUpload
					action={`${process.env.REACT_APP_SERVER_URL}/api/recipes/${recipe_id}/image/upload`}
					actionFunction={(options) => dispatch(form_image_upload(options, true))}
					fileList={fileList}
					setFileList={setFileList}
				/>
			)}
		</div>
	);
};

RecipeImageRender.propTypes = {
	recipeInfo: PropTypes.object.isRequired,
	fileList: PropTypes.array.isRequired,
	setFileList: PropTypes.func.isRequired
};

export default RecipeImageRender;
