import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Packages
import { Button, Popconfirm, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { DeleteFilled, EditFilled, EyeFilled, QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_all_users, user_delete } from '../../../redux/actions/usersActions';

const UsersTable = (props) => {
	const { messageApi, setIsModalOpen, setUpdate, setUserToUpdate, pageSize, setPageSize, skip, setSkip } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const navigate = useNavigate();

	const { all_users_loading, all_users, user_delete_loading } = useSelector(state => state.users);
	
	const update = (record) => {
		setIsModalOpen(true);
		setUpdate(true);
		setUserToUpdate(record);
	}

	const deleteUser = (record) => {
		let newSkip = skip;

		if ((all_users.count - 1) % pageSize === 0) {
			newSkip -= pageSize;
			setSkip(newSkip);
			localStorage.setItem('usersSkip', newSkip);
		}

		const filters = { skip: newSkip, limit: pageSize };
		dispatch(user_delete(messageApi, record._id.$oid, filters));
	}

	const columns = [
		{
			key: 'first_name',
			title: t('form.label.first_name'),
			dataIndex: 'first_name',
			render: (_, record) => (
				<Typography.Text copyable={{ text: record._id.$oid, tooltips: [t('table.copyableText.copyId'), t('table.copyableText.copiedId')] }}>
					{record.first_name}
				</Typography.Text>
			)
		},
		{
			key: 'last_name',
			title: t('form.label.last_name'),
			dataIndex: 'last_name'
		},
		{
			key: 'username',
			title: t('form.label.username'),
			dataIndex: 'username'
		},
		{
			key: 'email',
			title: t('form.label.email'),
			dataIndex: 'email'
		},
		{
			key: 'action',
			title: t('table.headers.actions'),
			dataIndex: 'action',
			render: (_, record) => (
				<Space>
					<Tooltip title={t('buttons.details')}>
						<Button
							type='primary'
							shape='circle'
							size='large'
							icon={<EyeFilled />}
							onClick={() => navigate(`/users/${record.key}/details`)}
						/>
					</Tooltip>
					<Tooltip title={t('buttons.edit')}>
						<Button
							type='primary'
							style={{ backgroundColor: '#80B4B9' }}
							shape='circle'
							size='large'
							icon={<EditFilled />}
							onClick={() => update(record)}
						/>
					</Tooltip>
					<Popconfirm
						title={t('users.delete.confirmTitle')}
						description={t('users.delete.confirmText')}
						icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
						onConfirm={() => deleteUser(record)}
						okButtonProps={{
							loading: user_delete_loading
						}}
						okText={t('buttons.confirm')}
						cancelText={t('buttons.cancel')}
					>
						<Button
							type='primary'
							shape='circle'
							size='large'
							danger
							icon={<DeleteFilled />}
						/>
					</Popconfirm>
				</Space>
			)
		}
	];

	const handleTableChange = (pagination, filters, sorter) => {
		const skip = (pagination.current - 1) * pagination.pageSize;
		setSkip(skip);
		localStorage.setItem('usersSkip', skip);

		const limit = pagination.pageSize;
		setPageSize(limit);
		localStorage.setItem('usersPageSize', limit);

		const usersFilters = { skip, limit };
		dispatch(get_all_users(usersFilters));
	};

	return (
		<Spin spinning={all_users_loading}>
			<Table
				columns={columns}
				dataSource={all_users.users}
				onChange={handleTableChange}
				scroll={{
					x: 'auto'
				}}
				pagination={{
					pageSize: pageSize, 
					total: all_users.count,
					showSizeChanger: true,
					showTotal: (total, range) => `${range[0]} - ${range[1]} ${t('table.pagination.of')} ${total}`,
					showTitle: false,
					responsive: true,
					locale: { items_per_page: `/ ${t('table.pagination.page')}` }
				}}
			/>
		</Spin>
	)
}

UsersTable.propTypes = {
	messageApi: PropTypes.object.isRequired,
	setIsModalOpen: PropTypes.func.isRequired,
	setUpdate: PropTypes.func.isRequired,
	setUserToUpdate: PropTypes.func.isRequired,
	pageSize: PropTypes.number.isRequired,
	setPageSize: PropTypes.func.isRequired,
	skip: PropTypes.number.isRequired,
	setSkip: PropTypes.func.isRequired
}

export default UsersTable;
