import React from 'react';

// Packages
import { Col, Divider, Row, Typography } from 'antd';

const TermsConditions = () => {
	return (
		<div
			style={{
				padding: '0 16px',
				maxWidth: '100%',
				width: '100%',
				margin: '0 auto'
			}}
		>
			<Row justify='center'>
				<Col
					xs={24} sm={22} md={20} lg={18} xl={16}
				>
					<div>
						<Typography.Title>TÉRMINOS Y CONDICIONES</Typography.Title>
						<Typography.Paragraph><strong>Última actualización:</strong> 22 de octubre de 2024</Typography.Paragraph>
						<Divider />
						
						<Typography.Title level={2} id='agreement-with-our-legal-terms'>ACUERDO CON NUESTROS TÉRMINOS LEGALES</Typography.Title>
						<Typography.Paragraph>Somos <strong>Kitchen And Manners S.A. de C.V.</strong> (“Compañía”, “nosotros”, “nos”, “nuestro”), una sociedad registrada en México en <strong>Fuente de Tirol No. 14, Lomas de Tecamachalco, Huixquilucan, Estado de México 52780.</strong></Typography.Paragraph>
						<Typography.Paragraph>Operamos la aplicación móvil <strong>Kitchen And Manners</strong> (“la App”), así como cualquier otro producto o servicio relacionado que refiera o enlace a estos términos legales (“Términos Legales”) (colectivamente, los “Servicios”). <strong>Kitchen And Manners</strong> es un juego educativo en el cual las personas pueden aprender a cocinar jugando, y posteriormente aplicar estos conocimientos en la vida real. <strong>Kitchen And Manners</strong> implementa técnicas de cocina real para que los usuarios sientan que están en una academia de cocina virtual.</Typography.Paragraph>
						<Typography.Paragraph>Puedes contactarnos por teléfono al <strong>+52 93100598</strong>, por correo electrónico a <strong>info@kitchenandmanner.com</strong>, o por correo postal en la dirección indicada anteriormente.</Typography.Paragraph>
						<Typography.Paragraph>Estos Términos Legales constituyen un acuerdo legalmente vinculante entre tú, personalmente o en representación de una entidad (“tú”), y <strong>Kitchen And Manners S.A. de C.V.</strong>, en relación con tu acceso y uso de los Servicios. Al acceder a los Servicios, confirmas haber leído, comprendido y aceptado estar vinculado por estos Términos Legales. SI NO ESTÁS DE ACUERDO CON TODOS ESTOS TÉRMINOS LEGALES, QUEDA EXPRESAMENTE PROHIBIDO EL USO DE LOS SERVICIOS Y DEBES DEJAR DE USARLOS INMEDIATAMENTE.</Typography.Paragraph>
						<Typography.Paragraph>Términos adicionales o documentos que puedan ser publicados ocasionalmente en los Servicios están expresamente incorporados en estos Términos Legales por referencia. Nos reservamos el derecho, a nuestra entera discreción, de hacer cambios o modificaciones a estos Términos Legales en cualquier momento y por cualquier motivo. Te notificaremos sobre cualquier cambio actualizando la fecha de “Última actualización” de estos Términos Legales, y renuncias a cualquier derecho a recibir una notificación específica de cada uno de esos cambios. Es tu responsabilidad revisar periódicamente estos Términos Legales para mantenerte informado de las actualizaciones. Estarás sujeto y se considerará que has aceptado los cambios en los Términos Legales revisados si continúas usando los Servicios después de la fecha en que se publiquen dichos Términos revisados.</Typography.Paragraph>
						<Typography.Paragraph>Todos los usuarios menores de edad en su jurisdicción de residencia (generalmente menores de 18 años) deben contar con el permiso de sus padres o tutores para utilizar los Servicios. Si eres menor de edad, debes pedir a tus padres o tutores que lean y acepten estos Términos Legales antes de usar los Servicios.</Typography.Paragraph>
						<Typography.Paragraph>Recomendamos que imprimas una copia de estos Términos Legales para tus registros.</Typography.Paragraph>

						<Typography.Title level={3} id='our-services'>1. NUESTROS SERVICIOS</Typography.Title>
						<Typography.Paragraph>La información proporcionada al usar los Servicios no está destinada a distribución o uso por cualquier persona o entidad en cualquier jurisdicción o país donde dicha distribución o uso sea contrario a la ley o a la regulación, o que nos someta a cualquier requisito de registro en dicha jurisdicción o país. Por lo tanto, las personas que eligen acceder a los Servicios desde otros lugares lo hacen por su propia iniciativa y son las únicas responsables de cumplir con las leyes locales, si estas son aplicables.</Typography.Paragraph>
						<Typography.Paragraph>Los Servicios no están diseñados para cumplir con regulaciones específicas de la industria (como la Ley de Portabilidad y Responsabilidad del Seguro de Salud - HIPAA, o la Ley Federal de Gestión de Seguridad de la Información - FISMA), por lo tanto, si tus interacciones están sujetas a dichas leyes, no puedes utilizar los Servicios de manera que violen dichas leyes.</Typography.Paragraph>
						
						<Typography.Title level={3} id='rights-of-intellectual-property'>2. DERECHOS DE PROPIEDAD INTELECTUAL</Typography.Title>
						<Typography.Title level={4} id='our-intellectual-property'>Nuestra propiedad intelectual</Typography.Title>
						<Typography.Paragraph>Somos los propietarios o licenciatarios de todos los derechos de propiedad intelectual en nuestros Servicios, incluyendo todo el código fuente, bases de datos, funcionalidad, software, diseño del sitio web, audio, video, texto, fotografías, y gráficos en los Servicios (colectivamente, el “Contenido”), así como las marcas registradas, marcas de servicio, y logotipos contenidos en ellos (las “Marcas”).</Typography.Paragraph>
						<Typography.Paragraph>Nuestro Contenido y Marcas están protegidos por leyes de derechos de autor y marcas registradas (y otras leyes de propiedad intelectual y de competencia desleal) y tratados en México y a nivel mundial.</Typography.Paragraph>
						<Typography.Paragraph>El Contenido y las Marcas se proporcionan en o a través de los Servicios “TAL COMO ESTÁN” para tu uso personal, no comercial, únicamente.</Typography.Paragraph>

						<Typography.Title level={4} id='your-use-of-our-services'>Tu uso de nuestros Servicios</Typography.Title>
						<Typography.Paragraph>Sujeto a tu cumplimiento con estos Términos Legales, incluidos los términos de la sección “ACTIVIDADES PROHIBIDAS” más adelante, te otorgamos una licencia no exclusiva, no transferible, revocable para:</Typography.Paragraph>
						<ul>
							<li><Typography.Text>Acceder a los Servicios; y</Typography.Text></li>
							<li><Typography.Text>Descargar o imprimir una copia de cualquier parte del Contenido al que hayas accedido correctamente, exclusivamente para tu uso personal, no comercial.</Typography.Text></li>
						</ul>
						<Typography.Paragraph>Excepto según lo dispuesto en esta sección o en cualquier otra parte de nuestros Términos Legales, ninguna parte de los Servicios, y ningún Contenido o Marca, puede ser copiado, reproducido, agregado, republicado, subido, publicado, públicamente exhibido, codificado, traducido, transmitido, distribuido, vendido, licenciado o explotado de otra manera para cualquier fin comercial, sin nuestro permiso previo por escrito.</Typography.Paragraph>
						<Typography.Paragraph>Si deseas hacer cualquier uso de los Servicios, Contenido o Marcas fuera de lo estipulado en esta sección o en cualquier otra parte de nuestros Términos Legales, dirígete a: <strong>info@kitchenandmanner.com</strong>. Si alguna vez te otorgamos el permiso para publicar, reproducir o mostrar públicamente cualquier parte de nuestros Servicios o Contenido, debes identificar claramente a <strong>Kitchen And Manners S.A. de C.V.</strong> como los propietarios o licenciatarios del Contenido o Marcas y asegurar que cualquier aviso de derechos de autor o propietario aparezca o sea visible en la publicación, reproducción o exhibición de dicho Contenido.</Typography.Paragraph>
						<Typography.Paragraph>Nos reservamos todos los derechos no expresamente otorgados a ti en relación con los Servicios, el Contenido y las Marcas.</Typography.Paragraph>
						<Typography.Paragraph>Cualquier incumplimiento de estos Derechos de Propiedad Intelectual constituirá una violación material de nuestros Términos Legales y tu derecho a usar nuestros Servicios terminará de inmediato.</Typography.Paragraph>

						<Typography.Title level={3} id='your-information-and-contributions'>3. TU INFORMACIÓN Y CONTRIBUCIONES</Typography.Title>
						<Typography.Title level={4} id='sendings'>Envíos</Typography.Title>
						<Typography.Paragraph>Al enviarnos directamente cualquier pregunta, comentario, sugerencia, idea, retroalimentación o cualquier otra información sobre los Servicios (“Envíos”), aceptas asignarnos todos los derechos de propiedad intelectual en dicho Envío. Aceptas que seremos los propietarios de este Envío y estaremos autorizados a su uso y difusión irrestrictos para cualquier propósito lícito, comercial o de otro tipo, sin reconocimiento o compensación para ti.</Typography.Paragraph>

						<Typography.Title level={4} id='contributions'>Contribuciones</Typography.Title>
						<Typography.Paragraph>Los Servicios pueden invitarte a chatear, contribuir o participar en blogs, foros de discusión, foros en línea, y otras funcionalidades a través de las cuales puedes crear, enviar, publicar, mostrar, transmitir, realizar, distribuir o transmitir contenido y materiales para nosotros o a través de los Servicios, incluyendo, pero no limitado a: texto, escritos, videos, audios, fotografías, gráficos, comentarios, sugerencias, calificaciones, información personal, u otro material (“Contribuciones”).</Typography.Paragraph>
						<Typography.Paragraph>Cualquier Contribución que publiques será visible para otros usuarios del Servicio. Cuando publicas Contribuciones, nos otorgas una licencia para usar tu nombre, marcas comerciales, y logotipos. Al publicar cualquier Contribución, nos otorgas un derecho mundial no exclusivo, irrevocable, ilimitado, transferible, perpetuo, y libre de regalías para usar, copiar, reproducir, distribuir, vender, revender, publicar, mostrar públicamente, reformatear, traducir y explotar tus Contribuciones (incluyendo sin limitación, tu imagen, nombre y voz) con cualquier propósito comercial o publicitario.</Typography.Paragraph>
						<Typography.Paragraph>Aceptas que tus Contribuciones sean visibles para otros usuarios de los Servicios y que también puedan estar disponibles en sitios web de terceros a través de los Servicios.</Typography.Paragraph>

						<Typography.Title level={4} id='your-responsibility'>Tu responsabilidad</Typography.Title>
						<Typography.Paragraph>Eres el único responsable de tus Envíos y Contribuciones. Aceptas no enviar o publicar a través de los Servicios ningún contenido que sea ilegal, hostigador, amenazante, difamatorio, obsceno, vulgar, ofensivo, fraudulento, o que infrinja derechos de propiedad intelectual de terceros.</Typography.Paragraph>
						<Typography.Paragraph>Nos reservamos el derecho de eliminar o modificar cualquier Contribución que, a nuestro juicio razonable, viole estos Términos Legales.</Typography.Paragraph>

						<Typography.Title level={3} id='representations-of-user'>4. REPRESENTACIONES DEL USUARIO</Typography.Title>
						<Typography.Paragraph>Al usar los Servicios, representas y garantizas que:</Typography.Paragraph>
						<ol>
							<li><Typography.Text>Toda la información de registro que envíes será veraz, precisa, actual y completa;</Typography.Text></li>
							<li><Typography.Text>Mantendrás la precisión de dicha información y la actualizarás puntualmente según sea necesario;</Typography.Text></li>
							<li><Typography.Text>Tienes la capacidad legal y aceptas cumplir con estos Términos Legales;</Typography.Text></li>
							<li><Typography.Text>No eres menor de edad en la jurisdicción en la que resides, o si eres menor de edad, has recibido permiso de tus padres para usar los Servicios;</Typography.Text></li>
							<li><Typography.Text>No accederás a los Servicios a través de medios automatizados o no humanos, como bots, scripts o de otro tipo;</Typography.Text></li>
							<li><Typography.Text>No utilizarás los Servicios para ningún propósito ilegal o no autorizado; y</Typography.Text></li>
							<li><Typography.Text>Tu uso de los Servicios no violará ninguna ley o reglamento aplicable.</Typography.Text></li>
						</ol>
						<Typography.Paragraph>Si proporcionas cualquier información que sea falsa, inexacta, no actual o incompleta, tenemos el derecho de suspender o cancelar tu cuenta y rechazar cualquier uso actual o futuro de los Servicios.</Typography.Paragraph>

						<Typography.Title level={3} id='registration-of-user'>5. REGISTRO DE USUARIO</Typography.Title>
						<Typography.Paragraph>Es posible que debas registrarte para utilizar los Servicios. Aceptas mantener la confidencialidad de tu contraseña y ser responsable de todo uso de tu cuenta y contraseña. Nos reservamos el derecho de eliminar, reclamar o cambiar el nombre de usuario que selecciones si determinamos, a nuestra discreción, que dicho nombre de usuario es inapropiado, obsceno o de alguna manera objetable.</Typography.Paragraph>

						<Typography.Title level={3} id='products'>6. PRODUCTOS</Typography.Title>
						<Typography.Paragraph>Hacemos todo lo posible por mostrar con la mayor precisión posible los colores, características, especificaciones y detalles de los productos disponibles en los Servicios. Sin embargo, no garantizamos que los colores, características, especificaciones y detalles de los productos sean precisos, completos, fiables, actuales o libres de otros errores, y tu pantalla electrónica puede no reflejar con precisión los colores y detalles reales de los productos.</Typography.Paragraph>
						<Typography.Paragraph>Nos reservamos el derecho de descontinuar cualquier producto en cualquier momento por cualquier razón. Los precios para todos los productos están sujetos a cambios.</Typography.Paragraph>

						<Typography.Title level={3} id='purchases-and-payments'>7. COMPRAS Y PAGOS</Typography.Title>
						<Typography.Paragraph>Kitchen And Manners ofrece sus productos y servicios a la venta exclusivamente a través de las tiendas electrónicas de Google Play y Apple Store. Por lo tanto, todas las compras realizadas dentro de la aplicación están sujetas a las políticas y términos de servicio de dichas plataformas. Cualquier reclamación, solicitud de reembolso o consulta relacionada con las compras realizadas debe gestionarse directamente a través de Google Play o Apple Store, de acuerdo con sus términos y políticas. Kitchen And Manners no gestiona reembolsos directamente ni tiene control sobre las políticas de reembolso de estas plataformas.</Typography.Paragraph>

						<Typography.Title level={3} id='prohibited-activities'>8. ACTIVIDADES PROHIBIDAS</Typography.Title>
						<Typography.Paragraph>No puedes acceder o utilizar los Servicios para ningún propósito que no sea aquel para el cual ponemos los Servicios a disposición. Los Servicios no pueden utilizarse en relación con ninguna actividad comercial salvo aquellas específicamente respaldadas o aprobadas por nosotros.</Typography.Paragraph>
						<Typography.Paragraph>Como usuario de los Servicios, aceptas no:</Typography.Paragraph>
						<ol>
							<li><Typography.Text>Recuperar sistemáticamente datos u otros contenidos de los Servicios para crear o compilar, directa o indirectamente, una colección, compilación, base de datos o directorio sin nuestro permiso por escrito.</Typography.Text></li>
							<li><Typography.Text>Engañar, defraudar o confundirnos a nosotros y a otros usuarios, en especial en cualquier intento de obtener información confidencial de cuentas, como contraseñas de usuarios.</Typography.Text></li>
							<li><Typography.Text>Evitar, desactivar o interferir de cualquier otra manera con funciones relacionadas con la seguridad de los Servicios, incluidas las funciones que impiden o restringen el uso o la copia de cualquier Contenido, o imponen limitaciones al uso de los Servicios y/o el Contenido que contiene.</Typography.Text></li>
							<li><Typography.Text>Desacreditar, manchar o perjudicar, en nuestra opinión, a nosotros y/o a los Servicios.</Typography.Text></li>
							<li><Typography.Text>Hacer uso indebido de nuestros servicios de soporte o enviar informes falsos de abuso o mala conducta.</Typography.Text></li>
							<li><Typography.Text>Usar los Servicios de una manera inconsistente con cualquier ley o reglamento aplicable.</Typography.Text></li>
							<li><Typography.Text>Participar en el enmarcado no autorizado o enlaces a los Servicios.</Typography.Text></li>
							<li><Typography.Text>Cargar o transmitir (o intentar cargar o transmitir) virus, caballos de Troya o cualquier otro material, incluidos el uso excesivo de letras mayúsculas y el spam (publicación continua de texto repetitivo), que interfiera con el uso y disfrute ininterrumpido de los Servicios por parte de cualquier persona, o que modifique, altere, interrumpa, afecte negativamente, o interfiera con el uso, características, funciones, operación o mantenimiento de los Servicios.</Typography.Text></li>
							<li><Typography.Text>Participar en cualquier uso automatizado del sistema, como el uso de secuencias de comandos para enviar comentarios o mensajes, o usar cualquier herramienta de minería de datos, robots o herramientas similares de recopilación y extracción de datos.</Typography.Text></li>
							<li><Typography.Text>Eliminar el aviso de derechos de autor u otros derechos de propiedad de cualquier Contenido.</Typography.Text></li>
							<li><Typography.Text>Hacerse pasar por otro usuario o persona, o usar el nombre de usuario de otro usuario.</Typography.Text></li>
							<li><Typography.Text>Cargar o transmitir (o intentar cargar o transmitir) cualquier material que actúe como mecanismo de recopilación o transmisión de información pasiva o activa, incluidos, sin limitación, formatos gráficos intercambiables (“gifs”), píxeles 1x1, errores web, cookies u otros dispositivos similares (a veces denominados “spyware” o mecanismos de recopilación pasiva” o “pcms”).</Typography.Text></li>
							<li><Typography.Text>Interferir, interrumpir o crear una carga indebida en los Servicios o las redes o servicios conectados a los Servicios.</Typography.Text></li>
							<li><Typography.Text>Acosar, molestar, intimidar o amenazar a cualquiera de nuestros empleados o agentes involucrados en el suministro de cualquier parte de los Servicios hacia ti.</Typography.Text></li>
							<li><Typography.Text>Intentar eludir cualquier medida de los Servicios diseñada para prevenir o restringir el acceso a los Servicios, o cualquier parte de los Servicios.</Typography.Text></li>
							<li><Typography.Text>Copiar o adaptar el software de los Servicios, incluidos, entre otros, Flash, PHP, HTML, JavaScript o cualquier otro código.</Typography.Text></li>
							<li><Typography.Text>Excepto según lo permitido por la ley aplicable, descifrar, descompilar, desensamblar o realizar ingeniería inversa en cualquiera del software que comprende o que forma parte de los Servicios de cualquier manera.</Typography.Text></li>
							<li><Typography.Text>Excepto como puede ser el resultado del uso estándar del motor de búsqueda o del navegador de Internet, usar, lanzar, desarrollar o distribuir cualquier sistema automatizado, incluidos, entre otros, cualquier araña, robot, herramienta de trampas, raspador o lector fuera de línea que acceda a los Servicios, o usar o lanzar cualquier secuencia de comandos no autorizada u otro software.</Typography.Text></li>
							<li><Typography.Text>Usar un agente de compras o un agente de compras para realizar compras en los Servicios.</Typography.Text></li>
							<li><Typography.Text>Hacer cualquier uso no autorizado de los Servicios, incluyendo la recopilación de nombres de usuario y/o direcciones de correo electrónico de usuarios mediante medios electrónicos u otros con el fin de enviar correos electrónicos no solicitados, o crear cuentas de usuario por medios automatizados o bajo pretextos.</Typography.Text></li>
							<li><Typography.Text>Usar los Servicios como parte de cualquier esfuerzo para competir con nosotros o utilizar los Servicios y/o el Contenido para cualquier esfuerzo generador de ingresos o empresa comercial.</Typography.Text></li>
							<li><Typography.Text>Usar los Servicios para anunciar o vender bienes y servicios.</Typography.Text></li>
							<li><Typography.Text>Vender, distribuir o compartir contenido como recetas, ingredientes, medidas, técnicas sin autorización.</Typography.Text></li>
						</ol>

						<Typography.Title level={3} id='user-generated-contributions'>9. CONTRIBUCIONES GENERADAS POR EL USUARIO</Typography.Title>
						<Typography.Paragraph>Los Servicios pueden permitirte crear, enviar, publicar, mostrar, transmitir o distribuir contenido y materiales, como texto, videos, fotos, comentarios y sugerencias (en adelante, “Contribuciones”). Cualquier Contribución que publiques podrá ser vista por otros usuarios de los Servicios y a través de sitios web de terceros.</Typography.Paragraph>
						<Typography.Paragraph>Cuando publicas Contribuciones, declaras y garantizas que:</Typography.Paragraph>
						<ul>
							<li><Typography.Text>Eres el propietario o tienes los derechos necesarios para usar y publicar las Contribuciones.</Typography.Text></li>
							<li><Typography.Text>Las Contribuciones no contienen ningún material que sea ofensivo, difamatorio, amenazante, ilegal o que infrinja los derechos de terceros.</Typography.Text></li>
							<li><Typography.Text>Tus Contribuciones no violan ninguna ley aplicable ni infringen los derechos de privacidad de terceros.</Typography.Text></li>
						</ul>
						<Typography.Paragraph>Nos reservamos el derecho de eliminar cualquier Contribución que consideremos inapropiada o que viole estos Términos Legales.</Typography.Paragraph>

						<Typography.Title level={3} id='license-of-contribution'>10. LICENCIA DE CONTRIBUCIÓN</Typography.Title>
						<Typography.Paragraph>Al publicar tus Contribuciones en cualquier parte de los Servicios, nos otorgas automáticamente una licencia mundial, no exclusiva, irrevocable, transferible, perpetua y libre de regalías para usar, copiar, reproducir, distribuir, vender, revender, publicar, mostrar públicamente, reformatear, traducir y explotar tus Contribuciones con fines comerciales, publicitarios u otros. Esta licencia incluye el derecho a sublicenciar estos derechos y se aplica a cualquier forma, medio o tecnología ahora conocida o desarrollada en el futuro.</Typography.Paragraph>

						<Typography.Title level={3} id='reviews-and-ratings'>11. RESEÑAS Y CALIFICACIONES</Typography.Title>
						<Typography.Paragraph>Podemos proporcionarte áreas dentro de los Servicios para dejar reseñas o calificaciones. Al publicar una reseña, debes cumplir con los siguientes criterios:</Typography.Paragraph>
						<ol>
							<li><Typography.Text>Debes haber tenido una experiencia directa con la persona o entidad que estás reseñando.</Typography.Text></li>
							<li><Typography.Text>Tus reseñas no deben contener lenguaje obsceno o abusivo.</Typography.Text></li>
							<li><Typography.Text>Tus reseñas no deben contener contenido que sea ofensivo, discriminatorio o ilegal.</Typography.Text></li>
							<li><Typography.Text>Tus reseñas deben ser veraces y no deben inducir a error a otros usuarios.</Typography.Text></li>
						</ol>
						<Typography.Paragraph>Nos reservamos el derecho de aceptar, rechazar o eliminar reseñas a nuestra entera discreción.</Typography.Paragraph>

						<Typography.Title level={3} id='license-of-application'>12. LICENCIA DE LA APLICACIÓN MÓVIL</Typography.Title>
						<Typography.Title level={4} id='use-of-the-application'>Uso de la Aplicación</Typography.Title>
						<Typography.Paragraph>Te otorgamos una licencia no exclusiva, revocable y no transferible para instalar y usar la App en tus dispositivos electrónicos, y para acceder y usar la App en dichos dispositivos estrictamente de acuerdo con estos Términos Legales.</Typography.Paragraph>
						<Typography.Paragraph>No puedes:</Typography.Paragraph>
						<ul>
							<li><Typography.Text>Modificar, traducir, descompilar o realizar ingeniería inversa sobre la App.</Typography.Text></li>
							<li><Typography.Text>Utilizar la App para crear un producto o servicio que compita con la App.</Typography.Text></li>
							<li><Typography.Text>Hacer uso de la App para enviar correos no solicitados o consultas automatizadas.</Typography.Text></li>
						</ul>

						<Typography.Title level={3} id='management-of-services'>13. GESTIÓN DE LOS SERVICIOS</Typography.Title>
						<Typography.Paragraph>Nos reservamos el derecho, pero no la obligación, de monitorear los Servicios para identificar violaciones a estos Términos Legales. También nos reservamos el derecho de eliminar cualquier contenido que a nuestra discreción razonable consideremos que viola los derechos de propiedad intelectual o sea perjudicial para otros usuarios.</Typography.Paragraph>

						<Typography.Title level={3} id='legislation'>14. LEGISLACIÓN APLICABLE</Typography.Title>
						<Typography.Paragraph>Estos Términos y Condiciones se rigen por las leyes de <strong>México</strong>. Cualquier disputa que surja en relación con estos términos será sometida a la jurisdicción exclusiva de los tribunales de <strong>Ciudad de México</strong>.</Typography.Paragraph>

						<Typography.Title level={3} id='modifications'>15. MODIFICACIONES</Typography.Title>
						<Typography.Paragraph>Nos reservamos el derecho de cambiar, modificar o eliminar cualquier parte de los Servicios sin previo aviso. No seremos responsables por cualquier pérdida, daño o inconveniente causado por la interrupción de los Servicios.</Typography.Paragraph>

						<Typography.Title level={3} id='privacy-policy'>16. POLÍTICA DE PRIVACIDAD</Typography.Title>
						<Typography.Paragraph>Nos preocupamos por la privacidad y seguridad de tus datos. Al usar los Servicios, aceptas estar vinculado por nuestra <strong>Política de Privacidad</strong> que puede ser consultada en <strong><a href={`${process.env.REACT_APP_SERVER_URL}/privacy-policy`} target='_blank' rel='noopener noreferrer'>{`${process.env.REACT_APP_SERVER_URL}/privacy-policy`}</a></strong>.</Typography.Paragraph>

						<Typography.Title level={3} id='author-rights'>17. AVISO Y POLÍTICA DE DERECHOS DE AUTOR (DMCA)</Typography.Title>
						<Typography.Paragraph>Respetamos los derechos de propiedad intelectual de otros. Si crees que cualquier material disponible en o a través de los Servicios infringe algún derecho de autor que posees o controlas, por favor notifica de inmediato a nuestro Agente Designado de Derechos de Autor usando la información de contacto proporcionada a continuación (una “Notificación”). Se enviará una copia de tu Notificación a la persona que publicó o almacenó el material señalado en la Notificación. Ten en cuenta que, de acuerdo con la ley federal de los Estados Unidos, puedes ser responsable de los daños si realizas representaciones falsas materiales en una Notificación. Por lo tanto, si no estás seguro de que el material ubicado en los Servicios o vinculado a través de los Servicios infrinja tu derecho de autor, considera ponerte en contacto con un abogado.</Typography.Paragraph>

						<Typography.Title level={4} id='notice-of-copyright'>Contra Notificación</Typography.Title>
						<Typography.Paragraph>Si crees que tu propio material protegido por derechos de autor ha sido retirado de los Servicios como resultado de un error o de una identificación errónea, puedes enviar una contra notificación por escrito a nuestro Agente Designado de Derechos de Autor usando la información de contacto proporcionada a continuación (una “Contra Notificación”). Para que sea efectiva, la Contra Notificación debe incluir sustancialmente lo siguiente:</Typography.Paragraph>
						<ol>
							<li><Typography.Text>Identificación del material que ha sido retirado o deshabilitado y la ubicación en la que el material apareció antes de ser retirado o deshabilitado.</Typography.Text></li>
							<li><Typography.Text>Una declaración de que aceptas la jurisdicción del tribunal federal del distrito judicial en el que se encuentra tu dirección, o si tu dirección está fuera de México, para cualquier distrito judicial en el que nos encontremos.</Typography.Text></li>
							<li><Typography.Text>Una declaración de que aceptarás la notificación de la persona que presentó la Notificación o del agente de esa persona.</Typography.Text></li>
							<li><Typography.Text>Tu nombre, dirección y número de teléfono.</Typography.Text></li>
							<li><Typography.Text>Una declaración bajo pena de perjurio de que tienes la creencia de buena fe de que el material en cuestión fue retirado o deshabilitado como resultado de un error o identificación incorrecta del material a ser retirado o deshabilitado.</Typography.Text></li>
							<li><Typography.Text>Tu firma física o electrónica.</Typography.Text></li>
						</ol>
						<Typography.Paragraph>Si nos envías una Contra Notificación válida por escrito, restauraremos el material eliminado o deshabilitado a menos que primero recibamos una notificación de la parte que presentó la Notificación indicando que esa parte ha solicitado una acción judicial para impedir que el usuario que presentó la Contra Notificación participe en actividades infractoras relacionadas con el material en cuestión. Ten en cuenta que, si representas de manera falsa que el contenido eliminado o deshabilitado fue retirado por error o identificación incorrecta, podrías ser responsable de los daños, incluidos los costos y honorarios de abogados.</Typography.Paragraph>

						<Typography.Title level={3} id='expiration-and-termination'>18. VIGENCIA Y TERMINACIÓN</Typography.Title>
						<Typography.Paragraph>Estos Términos Legales estarán en pleno vigor y efecto mientras uses los Servicios. SIN PERJUICIO DE CUALQUIER OTRA DISPOSICIÓN DE ESTOS TÉRMINOS LEGALES, NOS RESERVAMOS EL DERECHO, A NUESTRA ENTERA DISCRECIÓN Y SIN AVISO O RESPONSABILIDAD, A NEGAR EL ACCESO Y USO DE LOS SERVICIOS (INCLUYENDO BLOQUEAR CIERTAS DIRECCIONES IP) A CUALQUIER PERSONA POR CUALQUIER MOTIVO O SIN MOTIVO, INCLUYENDO SIN LIMITACIÓN POR INCUMPLIMIENTO DE CUALQUIER REPRESENTACIÓN, GARANTÍA O PACTO CONTENIDO EN ESTOS TÉRMINOS LEGALES O DE CUALQUIER LEY O REGULACIÓN APLICABLE. PODEMOS TERMINAR TU USO O PARTICIPACIÓN EN LOS SERVICIOS O ELIMINAR TU CUENTA Y CUALQUIER CONTENIDO O INFORMACIÓN QUE HAYAS PUBLICADO EN CUALQUIER MOMENTO, SIN ADVERTENCIA, A NUESTRA ENTERA DISCRECIÓN.</Typography.Paragraph>
						<Typography.Paragraph>Si cancelamos o suspendemos tu cuenta por cualquier motivo, tienes prohibido registrarte y crear una nueva cuenta bajo tu nombre, un nombre falso o prestado, o el nombre de cualquier tercero, incluso si puedes estar actuando en nombre del tercero. Además de cancelar o suspender tu cuenta, nos reservamos el derecho de tomar las acciones legales correspondientes, incluyendo, sin limitación, la búsqueda de recursos civiles, penales e injuntivos.</Typography.Paragraph>

						<Typography.Title level={3} id=''>19. MODIFICACIONES E INTERRUPCIONES</Typography.Title>
						<Typography.Paragraph>Nos reservamos el derecho de cambiar, modificar o eliminar los contenidos de los Servicios en cualquier momento o por cualquier motivo, a nuestra entera discreción, sin previo aviso. Sin embargo, no tenemos ninguna obligación de actualizar ninguna información en nuestros Servicios. También nos reservamos el derecho de modificar o interrumpir la totalidad o parte de los Servicios sin previo aviso en cualquier momento. No seremos responsables ante ti ni ante ningún tercero por cualquier modificación, cambio de precio, suspensión o interrupción de los Servicios.</Typography.Paragraph>
						<Typography.Paragraph>No podemos garantizar que los Servicios estarán disponibles en todo momento. Es posible que experimentemos problemas de hardware, software o de otro tipo, o necesitemos realizar tareas de mantenimiento relacionadas con los Servicios, lo que puede resultar en interrupciones, demoras o errores. Nos reservamos el derecho de cambiar, revisar, actualizar, suspender, interrumpir o modificar los Servicios en cualquier momento o por cualquier motivo sin previo aviso. Aceptas que no tenemos ninguna responsabilidad por cualquier pérdida, daño o inconveniente causado por tu incapacidad para acceder o usar los Servicios durante cualquier tiempo de inactividad o interrupción de los Servicios. Nada en estos Términos Legales se interpretará como una obligación de mantener y apoyar los Servicios o de proporcionar cualquier corrección, actualización o lanzamiento en relación con los mismos.</Typography.Paragraph>

						<Typography.Title level={3} id=''>20. LEY APLICABLE</Typography.Title>
						<Typography.Paragraph>Estos Términos Legales se regirán e interpretarán de acuerdo con las leyes de México. Kitchen And Manners S.A. de C.V. y tú aceptan irrevocablemente que los tribunales de México tendrán jurisdicción exclusiva para resolver cualquier disputa que pueda surgir en relación con estos Términos Legales.</Typography.Paragraph>

						<Typography.Title level={3} id=''>21. RESOLUCIÓN DE DISPUTAS</Typography.Title>
						<Typography.Title level={4}>Arbitraje Vinculante</Typography.Title>
						<Typography.Paragraph>Cualquier disputa que surja de o en relación con estos Términos Legales, incluyendo cualquier cuestión sobre su existencia, validez o terminación, será remitida y finalmente resuelta mediante arbitraje administrado por el Centro de Arbitraje de México (CAM) de acuerdo con las reglas de este Centro, que se considera incorporada por referencia a esta cláusula. El número de árbitros será uno (1). La sede o lugar legal del arbitraje será la Ciudad de México, México. El idioma del procedimiento será el español. La ley aplicable a estos Términos Legales será la ley sustantiva de México.</Typography.Paragraph>

						<Typography.Title level={4}>Restricciones</Typography.Title>
						<Typography.Paragraph>Las partes acuerdan que cualquier arbitraje se limitará a la disputa entre las partes individualmente. En la mayor medida permitida por la ley, (a) no se unirá ningún arbitraje con otro procedimiento; (b) no hay derecho o autoridad para que cualquier disputa sea arbitrada sobre una base de acción colectiva o para utilizar procedimientos de acción colectiva; y (c) no hay derecho o autoridad para que cualquier disputa sea presentada en una capacidad representativa en nombre del público en general o de cualquier otra persona.</Typography.Paragraph>

						<Typography.Title level={4}>Excepciones al Arbitraje</Typography.Title>
						<Typography.Paragraph>Las partes acuerdan que las siguientes disputas no están sujetas a las disposiciones anteriores en relación con el arbitraje vinculante: (a) cualquier disputa que busque hacer cumplir o proteger, o sobre la validez de, cualquiera de los derechos de propiedad intelectual de una parte; (b) cualquier disputa relacionada con, o derivada de, acusaciones de robo, piratería, invasión de la privacidad o uso no autorizado; y (c) cualquier reclamación de medidas cautelares. Si se determina que esta disposición es ilegal o inaplicable, entonces ninguna de las partes optará por arbitrar ninguna disputa que caiga dentro de esa parte de esta disposición que se encuentre ilegal o inaplicable, y dicha disputa será decidida por un tribunal de jurisdicción competente dentro de los tribunales listados para jurisdicción anteriormente, y las partes acuerdan someterse a la jurisdicción personal de ese tribunal.</Typography.Paragraph>

						<Typography.Title level={3} id=''>22. CORRECCIONES</Typography.Title>
						<Typography.Paragraph>Puede haber información en los Servicios que contenga errores tipográficos, inexactitudes u omisiones, incluyendo descripciones, precios, disponibilidad y varias otras informaciones. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión y de cambiar o actualizar la información en los Servicios en cualquier momento, sin previo aviso.</Typography.Paragraph>

						<Typography.Title level={3} id=''>23. EXENCIÓN DE RESPONSABILIDAD</Typography.Title>
						<Typography.Paragraph>LOS SERVICIOS SE PROPORCIONAN TAL COMO ESTÁN Y SEGÚN DISPONIBILIDAD. ACEPTAS QUE TU USO DE LOS SERVICIOS SERÁ BAJO TU PROPIO RIESGO. EN LA MAYOR MEDIDA PERMITIDA POR LA LEY, RENUNCIAMOS A TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS, EN RELACIÓN CON LOS SERVICIOS Y TU USO DE ESTOS, INCLUYENDO, SIN LIMITACIÓN, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y NO INFRACCIÓN. NO HACEMOS GARANTÍAS NI REPRESENTACIONES SOBRE LA EXACTITUD O INTEGRIDAD DEL CONTENIDO DE LOS SERVICIOS O EL CONTENIDO DE CUALQUIER SITIO WEB VINCULADO A LOS SERVICIOS Y NO ASUMIREMOS NINGUNA RESPONSABILIDAD POR (1) ERRORES, FALLOS O IMPRECISIONES DE CONTENIDO Y MATERIALES, (2) LESIONES PERSONALES O DAÑOS A LA PROPIEDAD, DE CUALQUIER NATURALEZA, RESULTANTES DE TU ACCESO Y USO DE LOS SERVICIOS, (3) CUALQUIER ACCESO NO AUTORIZADO A O USO DE NUESTROS SERVIDORES SEGUROS Y/O CUALQUIER INFORMACIÓN PERSONAL Y/O FINANCIERA ALMACENADA EN ELLOS, (4) CUALQUIER INTERRUPCIÓN O CESE DE LA TRANSMISIÓN HACIA O DESDE LOS SERVICIOS, (5) CUALQUIER ERROR, VIRUS, CABALLOS DE TROYA, O SIMILARES QUE PUEDAN SER TRANSMITIDOS HACIA O A TRAVÉS DE LOS SERVICIOS POR CUALQUIER TERCERO, Y/O (6) CUALQUIER ERROR U OMISIÓN EN CUALQUIER CONTENIDO Y MATERIALES O POR CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO OCASIONADO COMO RESULTADO DEL USO DE CUALQUIER CONTENIDO PUBLICADO, TRANSMITIDO O DISPONIBLE DE OTRO MODO A TRAVÉS DE LOS SERVICIOS. NO GARANTIZAMOS, RESPALDAMOS, GARANTIZAMOS NI ASUMIMOS RESPONSABILIDAD POR NINGÚN PRODUCTO O SERVICIO ANUNCIADO U OFRECIDO POR UN TERCERO A TRAVÉS DE LOS SERVICIOS, CUALQUIER SITIO WEB VINCULADO O CUALQUIER SITIO WEB O APLICACIÓN MÓVIL QUE APAREZCA EN CUALQUIER BANNER U OTRO TIPO DE PUBLICIDAD, Y NO SEREMOS PARTE NI DE NINGUNA MANERA SEREMOS RESPONSABLES DE SUPERVISAR CUALQUIER TRANSACCIÓN ENTRE TÚ Y CUALQUIER TERCERO PROVEEDOR DE PRODUCTOS O SERVICIOS.</Typography.Paragraph>

						<Typography.Title level={3} id=''>24. LIMITACIONES DE RESPONSABILIDAD</Typography.Title>
						<Typography.Paragraph>EN NINGÚN CASO NOSOTROS O NUESTROS DIRECTORES, EMPLEADOS O AGENTES SEREMOS RESPONSABLES ANTE TI O CUALQUIER TERCERO POR CUALQUIER DAÑO DIRECTO, INDIRECTO, CONSECUENTE, EJEMPLAR, INCIDENTAL, ESPECIAL O PUNITIVO, INCLUYENDO PÉRDIDA DE BENEFICIOS, PÉRDIDA DE INGRESOS, PÉRDIDA DE DATOS U OTROS DAÑOS QUE SURJAN DE TU USO DE LOS SERVICIOS, INCLUSO SI HEMOS SIDO ADVERTIDOS DE LA POSIBILIDAD DE DICHOS DAÑOS. SIN PERJUICIO DE NINGUNA DISPOSICIÓN EN CONTRARIO CONTENIDA AQUÍ, NUESTRA RESPONSABILIDAD ANTE TI POR CUALQUIER CAUSA Y SIN IMPORTAR LA FORMA DE LA ACCIÓN, ESTARÁ EN TODO MOMENTO LIMITADA A LA CANTIDAD PAGADA, SI LA HUBIERE, POR TI A NOSOTROS DURANTE EL PERIODO DE UN (1) MES ANTERIOR A CUALQUIER CAUSA DE ACCIÓN QUE SURJA. CIERTAS LEYES ESTATALES DE EE.UU. Y LEYES INTERNACIONALES NO PERMITEN LIMITACIONES EN GARANTÍAS IMPLÍCITAS O LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS. SI ESTAS LEYES APLICAN A TI, ALGUNAS O TODAS LAS EXCLUSIONES O LIMITACIONES ANTERIORES PUEDEN NO APLICARSE A TI, Y PUEDES TENER DERECHOS ADICIONALES.</Typography.Paragraph>

						<Typography.Title level={3} id=''>25. INDEMNIZACIÓN</Typography.Title>
						<Typography.Paragraph>Aceptas defender, indemnizar y mantener indemne a nosotros, incluyendo nuestras subsidiarias, afiliadas y todos nuestros respectivos funcionarios, agentes, socios y empleados, de y contra cualquier pérdida, daño, responsabilidad, reclamo o demanda, incluidos los honorarios y gastos razonables de abogados, realizados por cualquier tercero debido a o que surjan de: (1) tus Contribuciones; (2) uso de los Servicios; (3) incumplimiento de estos Términos Legales; (4) cualquier incumplimiento de tus representaciones y garantías establecidas en estos Términos Legales; (5) tu violación de los derechos de un tercero, incluidos, entre otros, los derechos de propiedad intelectual; o (6) cualquier acto perjudicial evidente hacia cualquier otro usuario de los Servicios con quien te hayas conectado a través de los Servicios. No obstante, lo anterior, nos reservamos el derecho, a tu cargo, de asumir la defensa y el control exclusivos de cualquier asunto para el cual debas indemnizarnos, y aceptas cooperar, a tu cargo, con nuestra defensa de tales reclamaciones. Haremos esfuerzos razonables para notificarte sobre cualquier reclamo, acción o procedimiento que esté sujeto a esta indemnización al tener conocimiento de ello.</Typography.Paragraph>

						<Typography.Title level={3} id=''>26. DATOS DEL USUARIO</Typography.Title>
						<Typography.Paragraph>Conservaremos ciertos datos que transmitas a los Servicios con el propósito de gestionar el rendimiento de los Servicios, así como datos relacionados con tu uso de los Servicios. Aunque realizamos copias de seguridad regulares de datos, eres el único responsable de todos los datos que transmitas o que se relacionen con cualquier actividad que hayas realizado utilizando los Servicios. Aceptas que no tendremos ninguna responsabilidad ante ti por cualquier pérdida o corrupción de dichos datos, y renuncias a cualquier derecho de acción contra nosotros que surja de dicha pérdida o corrupción de tales datos.</Typography.Paragraph>

						<Typography.Title level={3} id=''>27. COMUNICACIONES ELECTRÓNICAS, TRANSACCIONES Y FIRMAS</Typography.Title>
						<Typography.Paragraph>Visitar los Servicios, enviarnos correos electrónicos y completar formularios en línea constituyen comunicaciones electrónicas. Aceptas recibir comunicaciones electrónicas y aceptas que todos los acuerdos, avisos, divulgaciones y otras comunicaciones que te proporcionemos electrónicamente, a través de correo electrónico y en los Servicios, satisfacen cualquier requisito legal de que dicha comunicación sea por escrito. ACEPTAS EL USO DE FIRMAS ELECTRÓNICAS, CONTRATOS, PEDIDOS Y OTROS REGISTROS, Y LA ENTREGA ELECTRÓNICA DE AVISOS, POLÍTICAS Y REGISTROS DE TRANSACCIONES INICIADAS O COMPLETADAS POR NOSOTROS O A TRAVÉS DE LOS SERVICIOS. Por la presente, renuncias a cualquier derecho o requisito bajo cualquier ley, reglamento, regla, ordenanza u otra ley en cualquier jurisdicción que requiera una firma original o la entrega o retención de registros no electrónicos, o a pagos o la concesión de créditos por cualquier medio que no sea electrónico.</Typography.Paragraph>

						<Typography.Title level={3} id=''>28. USUARIOS Y RESIDENTES DE CALIFORNIA</Typography.Title>
						<Typography.Paragraph>Si cualquier queja con nosotros no se resuelve satisfactoriamente, puedes ponerte en contacto con la Unidad de Asistencia de Quejas de la División de Servicios al Consumidor del Departamento de Asuntos del Consumidor de California por escrito en 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 o por teléfono al (800) 952-5210 o (916) 445-1254.</Typography.Paragraph>

						<Typography.Title level={3} id=''>29. MISCELÁNEOS</Typography.Title>
						<Typography.Paragraph>Estos Términos Legales y cualquier política o reglas operativas publicadas por nosotros en los Servicios o con respecto a los Servicios constituyen el acuerdo y entendimiento completo entre tú y nosotros. Nuestra incapacidad para ejercer o hacer cumplir cualquier derecho o disposición de estos Términos Legales no operará como una renuncia a dicho derecho o disposición. Estos Términos Legales operan en la mayor medida permitida por la ley. Podemos asignar cualquiera o todos nuestros derechos y obligaciones a otros en cualquier momento. No seremos responsables de ninguna pérdida, daño, demora o falta de actuación causada por cualquier causa fuera de nuestro control razonable. Si alguna disposición o parte de una disposición de estos Términos Legales se determina que es ilegal, nula o inaplicable, esa disposición o parte de la disposición se considera separable de estos Términos Legales y no afecta la validez y aplicabilidad de las disposiciones restantes. No se crea ninguna relación de empresa conjunta, sociedad, empleo o agencia entre tú y nosotros como resultado de estos Términos Legales o el uso de los Servicios. Aceptas que estos Términos Legales no se interpretarán en nuestra contra por haberlos redactado. Por la presente, renuncias a cualquier defensa que puedas tener basada en la forma electrónica de estos Términos Legales y la falta de firma por parte de las partes para ejecutar estos Términos Legales.</Typography.Paragraph>

						<Typography.Title level={3} id=''>30. CONTÁCTANOS</Typography.Title>
						<Typography.Paragraph>Para resolver una queja con respecto a los Servicios o para recibir más información sobre el uso de los Servicios, por favor contáctanos en:</Typography.Paragraph>

						<Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'><strong>Kitchen And Manners S.A. de C.V.</strong></Typography.Paragraph>
						<Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'><strong>Fuente de Tirol No. 14, Lomas de Tecamachalco</strong></Typography.Paragraph>
						<Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'><strong>Huixquilucan, Estado de México 52780, México</strong></Typography.Paragraph>
						<Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'><strong>Teléfono: +52 93100598</strong></Typography.Paragraph>
						<Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'><strong>Correo electrónico: info@kitchenandmanner.com</strong></Typography.Paragraph>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default TermsConditions;
