import React from 'react';

// Packages
import { Col, Layout, Row, Typography } from 'antd';

const LegalHeader = () => {
	return (
		<Layout.Header
			style={{
				background: '#F18B71',
				position: 'sticky',
				top: 0,
				zIndex: 1,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				height: 64, // Sets a fixed height
			}}
		>
			<div
				style={{
					padding: '0 16px',
					maxWidth: '100%',
					width: '100%',
					margin: '0 auto',
				}}
			>
				<Row justify="center">
					<Col xs={24} sm={22} md={20} lg={18} xl={16}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<img
								style={{ height: '47px', marginRight: '2rem' }}
								src={process.env.PUBLIC_URL + '/assets/KM_simbolo.png'}
							/>
							<Typography.Title
								level={4}
								style={{ color: 'white', margin: 0 }}
							>
								Legal
							</Typography.Title>
						</div>
					</Col>
				</Row>
			</div>
		</Layout.Header>
	)
}

export default LegalHeader;
